import React, { useState } from 'react';
import '../../../styles/store.css';
import { BiSearch, BiX } from 'react-icons/bi'; // Import the BiSearch and BiX icons from react-icons/bi
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea'; // Import ProfileArea component

function EmergancyService() {

  const [showNumbers, setShowNumbers] = useState(false); // State to toggle showing emergency service numbers

  // Function to toggle showing emergency service numbers
  const toggleShowNumbers = () => {
    setShowNumbers(!showNumbers);
    if (!showNumbers) {
      // Open PDF link in new tab
      window.open('https://www.irtsa.net/pdfdocs/List-of-Emergency-Helpline-Numbers-All-Over-in-India.pdf', '_blank');
    }
  };

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          <div >
            <div className="button-emergancy">View Service Numbers<img src="../../icon/box-arrow.png" alt="icon" onClick={toggleShowNumbers} /></div>
           </div>
           {/* Display emergency service numbers if showNumbers is true */}
           {/* {showNumbers && ( */}
              <div className="emergency-numbers">
                {/* List of emergency service numbers */}

                 <img src="../../img/emegancy-number.png" alt="icon" style={{ width:'89%' }} />
                
              </div>
            {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default EmergancyService;

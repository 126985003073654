import React from 'react';
import '../../../styles/store.css';
import { BiSearch, BiX } from 'react-icons/bi'; // Import the BiSearch and BiX icons from react-icons/bi
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea'; // Import ProfileArea component

function NewsCommunityPosting() {

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          
          <div className="card-container">
          <div className="card">
            <img src="../../icon/working.jpg" alt="Blog Post" />
              <div className="card-content">
                <h2 className='blg-heading'>Namsbel Blog</h2>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo voluptatibus earum, excepturi quis natus enim quas reprehenderit illo commodi voluptas, ex qui quaerat debitis ratione perspiciatis nobis at itaque! Beatae!</p>
                <div className="last-line">
                  <img src="../../icon/profile.jpg" alt="" />
                  <p>Nikhith</p>
                  <p>17th May 2024</p>
                </div>
              </div>
            </div>
            <div className="card">
            <img src="../../icon/working.jpg" alt="Blog Post" />
              <div className="card-content">
                <h2 className='blg-heading'>Namsbel Blog</h2>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo voluptatibus earum, excepturi quis natus enim quas reprehenderit illo commodi voluptas, ex qui quaerat debitis ratione perspiciatis nobis at itaque! Beatae!</p>
                <div className="last-line">
                  <img src="../../icon/profile.jpg" alt="" />
                  <p>Nikhith</p>
                  <p>17th May 2024</p>
                </div>
              </div>
            </div>
            </div>
          
        </div>
      </div>
    </div>
  );
}

export default NewsCommunityPosting;

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, Navigate  } from 'react-router-dom'; // Combine imports from 'react-router-dom'
import { BASE_URL } from '../../utils/api'; // Adjust the path as needed
import broImage from '../../assets/img/bro.png';
import empImage from '../../assets/img/emp-pic-login.png';
import logogf from '../../assets/img/@-logo.gif';
import shape from '../../assets/img/shapes.gif';
import logingif from '../../assets/img/arrow.gif';

// import '../../styles/login.css'; // Adjust the import path for the styles

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    document.title = "Promoterpal-Login";
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('id', data.id);
        localStorage.setItem('userName', data.userName);
        localStorage.setItem('role_id', data.role_id);
        localStorage.setItem('token', data.token);
        localStorage.setItem('isAuthenticated', 'true');

        window.location.reload()

        if (data.role_id === 3) {
          return <Navigate to="/promoter/dashboard" replace />;
        } else if (data.role_id === 2) {
          return <Navigate to="/employer/dashboard" replace />;
        }
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const location = useLocation();
  const path = location.pathname;

  const renderHeading = () => {
    if (path === '/') {
      return <h2 className="login-text">Promoter Login</h2>;
    } else if (path === '/employer/login') {
      return <h2 className="login-text">Company Login</h2>;
    }
    return null;
  };

  const renderSignupLink = () => {
    if (path === '/') {
      return <p>Don't have an account? Please <Link to="/promoter/signup">Sign Up</Link></p>;
    } else if (path === '/employer/login') {
      return <p>Don't have an account? Please <Link to="/employer/signup">Sign Up</Link></p>;
    }
    return null;
  };

  const imgRender = () => {
    if (path === '/') {
      return <img id="login-image" src={empImage} alt="image not found" />;
    } else if (path === '/employer/login') {
      return <img id="login-image" src={broImage} alt="image not found" />
    }
    return null;
  };

  return (
      <div className="container">
        <div className="left-login-img">
          <div className="Photo">
            {imgRender()}
          </div>
        </div>
        <div className="right-login-form">
           {renderHeading()}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <form className="login-form" onSubmit={handleFormSubmit}>
            <div className="input-box">
              <img src={logogf} alt="" />
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Your Email ID"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-box">
              <img src={shape} alt="" />
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Link to="/forgot-password" className="forgot-password">Forgot password ?</Link>
            <button type="submit" className="btn-btn">Login <img id="arrow" src={logingif} alt="" /></button>
          </form>
          <div className="signup-link">
             {renderSignupLink()}
          </div>
          {/* <hr className="dividerx" />
          <div className="others-option">Or Log In Using</div> */}
          {/* <div className="social-login">
            <button type="button" className="social-button google">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google" viewBox="0 0 16 16">
                <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
              </svg>
            </button>
            <button type="button" className="social-button linkedin">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
              </svg>
            </button>
            <button type="button" className="social-button facebook">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
              </svg>
            </button>
          </div> */}
        </div>
      </div>
  );
}

export default Login;

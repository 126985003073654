import React from 'react';
import '../../../styles/store.css';
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea'; // Import ProfileArea component

function HealthInsurance() {
  // const linkStyle = {
  //   textDecoration: 'none',
  //   color: 'inherit', // Inherit color from parent to ensure it matches with the <p> tag
  // };

  // const pStyle = {
  //   color: 'black',
  //   textDecoration: 'none',
  // };

  // const imgStyle = {
  //   width: '100px', // Set the width of the logo
  //   height: '100px', // Set the height of the logo
  //   objectFit: 'contain', // Ensure the logo maintains its aspect ratio
  // };

  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    color: 'white',
    fontSize: '16px',
    margin: '10px',
    padding: '10px'
  };
  
  const pStyle = {
    margin: '10px 0 0 0',
    fontWeight: 'bold',
    textAlign: 'center'
  };



  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          <div className="payment-container">
            <a href="https://www.licindia.in" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#004d99' }}>
              {/* <img src="../../icon/lic-logo.png" alt="LIC" style={imgStyle} /> */}
              <p style={pStyle}>LIC</p>
            </a>
            <a href="https://www.icicilombard.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#f58220' }}>
              {/* <img src="../../icon/icici-lombard-logo.png" alt="ICICI Lombard" style={imgStyle} /> */}
              <p style={pStyle}>ICICI Lombard</p>
            </a>
            <a href="https://www.religarehealthinsurance.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#005f50' }}>
              {/* <img src="../../icon/religare-logo.png" alt="Religare Health Insurance" style={imgStyle} /> */}
              <p style={pStyle}>Religare Health Insurance</p>
            </a>
            <a href="https://www.naviinsurance.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ffcc00' }}>
              {/* <img src="../../icon/navi-health-logo.jpg" alt="Navi Health Insurance" style={imgStyle} /> */}
              <p style={pStyle}>Navi Health Insurance</p>
            </a>
            <a href="https://www.manipalcigna.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#2d5aa0' }}>
              {/* <img src="../../icon/layout_set_logo.png" alt="ManipalCigna Health Insurance" style={imgStyle} /> */}
              <p style={pStyle}>ManipalCigna Health Insurance</p>
            </a>
            <a href="https://www.adityabirlacapital.com/healthinsurance" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ed1c24' }}>
              {/* <img src="../../icon/ABHI-logo.jpg" alt="Aditya Birla Health Insurance" style={imgStyle} /> */}
              <p style={pStyle}>Aditya Birla Health Insurance</p>
            </a>
            <a href="https://www.reliancegeneral.co.in/Insurance/Health-Insurance.aspx" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#f6821f' }}>
              {/* <img src="../../icon/reliance-logo.png" alt="Reliance Health Insurance" style={imgStyle} /> */}
              <p style={pStyle}>Reliance Health Insurance</p>
            </a>
            <a href="https://www.hdfcergo.com/health-insurance" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#005bac' }}>
              {/* <img src="../../icon/hdfc-ergo-logo.png" alt="HDFC ERGO Health Insurance" style={imgStyle} /> */}
              <p style={pStyle}>HDFC ERGO Health Insurance</p>
            </a>
            <a href="https://www.acko.com/health-insurance" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#6cb33f' }}>
              {/* <img src="../../icon/acko-logo.png" alt="ACKO Health Insurance" style={imgStyle} /> */}
              <p style={pStyle}>ACKO Health Insurance</p>
            </a>
            <a href="https://www.bajajallianz.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#0033a0' }}>
              {/* <img src="../../icon/bajaj-allianz-logo.png" alt="Bajaj Allianz General Insurance" style={imgStyle} /> */}
              <p style={pStyle}>Bajaj Allianz General Insurance</p>
            </a>
            <a href="https://www.cholainsurance.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#004e45' }}>
              {/* <img src="../../icon/cholamandalam-logo.png" alt="Cholamandalam MS General Insurance" style={imgStyle} /> */}
              <p style={pStyle}>Cholamandalam MS General Insurance</p>
            </a>
            <a href="https://general.futuregenerali.in" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ff6600' }}>
              {/* <img src="../../icon/future-generali-logo.png" alt="Future Generali India Insurance" style={imgStyle} /> */}
              <p style={pStyle}>Future Generali India Insurance</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HealthInsurance;

import React, { useEffect, useState } from 'react';
import '../../styles/dashboard.css';
import JobSeekerSidebar from '../common/JobSeekerSidebar';
import profileImage from '../../assets/images/profile.jpg';
import Header from '../common/Header';
import ProfileArea from '../common/ProfileArea';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../utils/api';
import axios from 'axios';


function PromoterEditProfile() {
  const [showNotification, setShowNotification] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated');
    if (storedIsAuthenticated === 'true') {
      setIsAuthenticated(true);
    } else {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchProfileDetails = async () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('id');
      try {
        const response = await fetch(`${BASE_URL}/api/job-seeker-profile/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setProfileData(data.data);
          setFormData(data.data);
        } else {
          console.error('Failed to fetch profile details');
        }
      } catch (error) {
        console.error('Error fetching profile details:', error);
      }
    };

    if (isAuthenticated) {
      fetchProfileDetails();
    }
  }, [isAuthenticated]);

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  const handleEditButtonClick = () => {
    setIsEditMode(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const toggleSidebar = () => {
    const sidebar = document.querySelector('.sidebar');
    const profileForm = document.querySelector('.profile-form');
    
    // Toggle sidebar visibility
    sidebar.classList.toggle('open');
    // Toggle profile form visibility
    profileForm.classList.toggle('hide');
  };

  const handleSubmit = async (e) => {

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('id');
    e.preventDefault();
    setIsEditMode(false);
    
    try {
      const response = await axios.post(`${BASE_URL}/api/update/job-seeker-profile/${userId}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        setSuccessMessage('Profile updated successfully');
        // Optionally, reset form fields or perform any other actions
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // User not found
        // Display appropriate error message
      } else {
        // Other errors
        // Display generic error message or error from the backend
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea showNotification={showNotification} toggleNotification={toggleNotification} profileImage={profileImage} toggleSidebar={toggleSidebar} />
          <hr />
                {successMessage && (
              <div className="success-message" style={{ color: 'green' }}>
                {successMessage}
              </div>
            )}
            <button className="edit-button" onClick={handleEditButtonClick} disabled={isEditMode}>
            <img src="../../assets/img/edit.png" alt="" />
            Edit
          </button>
          <form className="profile-form" onSubmit={handleSubmit}>
            {Object.entries(formData).map(([key, value]) => {
              if (key !== 'role_id' && key !== 'user_id' && key !== 'profile_pics') {
                return (
                  <div className="form-group" key={key}>
                    <label>{key.replace(/_/g, ' ').toUpperCase()} :</label>
                    <input
                      type="text"
                      name={key}
                      placeholder={key.replace(/_/g, ' ')}
                      value={value}
                      onChange={handleInputChange}
                      disabled={!isEditMode || key === 'email'}
                    />
                  </div>
                );
              }
              return null;
            })}
            <button className="change-password" type="submit" disabled={!isEditMode}>
              <img src="../../assets/img/autorenew-logo.gif" alt="" />
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PromoterEditProfile;

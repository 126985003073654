import React, { useState, useEffect, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import '../../../styles/store.css';
import '../../../styles/HospitalLocator.css'; // Import the new CSS file
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea';

function HospitalLocator() {
  const [currentLocation, setCurrentLocation] = useState("");
  const [hospitals, setHospitals] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    if (mapRef.current === null) {
      mapRef.current = L.map(mapContainerRef.current).setView([12.2958, 76.6394], 13); // Centered on Mysore, Karnataka

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      }).addTo(mapRef.current);
    }
  }, []);

  const searchNearbyHospitals = async () => {
    try {
      // Use Geocoding API to get latitude and longitude of the current location
      const geocodingResponse = await fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(currentLocation)}&format=json&addressdetails=1&limit=1`);
      const geocodingData = await geocodingResponse.json();

      if (geocodingData.length === 0) {
        setErrorMessage("Location not found. Please enter a valid location.");
        return;
      }

      const { lat, lon } = geocodingData[0];
      mapRef.current.setView([lat, lon], 13);

      let overpassQuery = `node["amenity"="hospital"](around:5000,${lat},${lon});`;
      const locationParts = currentLocation.split(' ');
      if (locationParts.length > 1) {
        overpassQuery = `node["amenity"="hospital"]["name"~"${currentLocation}",i](around:5000,${lat},${lon});`;
      }

      // Use Overpass API to search for hospitals near the provided location
      const overpassResponse = await fetch(`https://overpass-api.de/api/interpreter?data=[out:json];(${overpassQuery});out;`);
      const overpassData = await overpassResponse.json();

      if (!overpassData.elements || overpassData.elements.length === 0) {
        setErrorMessage("No hospitals found nearby.");
        return;
      }

      const nearbyHospitals = overpassData.elements.map(hospital => ({
        name: hospital.tags.name || "Unnamed Hospital",
        lat: hospital.lat,
        lon: hospital.lon,
        address: hospital.tags["addr:full"] || "Unknown Address"
      }));

      setHospitals(nearbyHospitals);
      setErrorMessage("");

      // Clear existing markers
      mapRef.current.eachLayer(layer => {
        if (layer.options && layer.options.pane === "markerPane") {
          mapRef.current.removeLayer(layer);
        }
      });

      // Add new markers
      nearbyHospitals.forEach(hospital => {
        const marker = L.marker([hospital.lat, hospital.lon]).addTo(mapRef.current);
        const hospitalLink = `https://www.openstreetmap.org/?mlat=${hospital.lat}&mlon=${hospital.lon}#map=18/${hospital.lat}/${hospital.lon}`;
        marker.bindPopup(`<b>${hospital.name}</b><br>${hospital.address}<br><a href="${hospitalLink}" target="_blank">More details</a>`);
      });

    } catch (error) {
      console.error("Error searching hospitals:", error);
      setErrorMessage("An error occurred while searching for hospitals. Please try again later.");
    }
  };

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          <div className="container-3">
            <label htmlFor="currentLocation">Current Location</label>
            <input
              type="text"
              id="currentLocation"
              className="locator"
              value={currentLocation}
              onChange={(e) => setCurrentLocation(e.target.value)}
            />
            <button className="button-locator" onClick={searchNearbyHospitals}>
              Find Nearby Hospitals
            </button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div id="map" ref={mapContainerRef}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HospitalLocator;

import React from 'react';
import '../../../styles/store.css';
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea'; // Import ProfileArea component

function BookingApps() {
  // const linkStyle = {
  //   textDecoration: 'none',
  //   color: 'inherit', // Inherit color from parent to ensure it matches with the <p> tag
  // };

  // const pStyle = {
  //   color: 'black',
  //   textDecoration: 'none',
  // };

  // const imgStyle = {
  //   width: '100px', // Set the width of the logo
  //   height: '100px', // Set the height of the logo
  //   objectFit: 'contain', // Ensure the logo maintains its aspect ratio
  //   marginBottom: '10px',
  // };

  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    color: 'white',
    fontSize: '16px',
    margin: '10px',
    padding: '10px'
  };
  
  const pStyle = {
    margin: '10px 0 0 0',
    fontWeight: 'bold',
    textAlign: 'center'
  };


  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
            <div className="booking-container">
            <h3>Air/Bus Booking Apps</h3>
            <div className="payment-container">
              <a href="https://www.redbus.in" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#d84e55' }}>
                {/* <img src="../../icon/redbus-logo.png" alt="RedBus" style={imgStyle} /> */}
                <p style={pStyle}>RedBus</p>
              </a>
              <a href="https://www.abhibus.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#d84e15' }}>
                {/* <img src="../../icon/abhibus-logo.png" alt="AbhiBus" style={imgStyle} /> */}
                <p style={pStyle}>AbhiBus</p>
              </a>
              <a href="https://www.makemytrip.com/bus-tickets/" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: 'rgb(255, 87, 51)' }}>
                {/* <img src="../../icon/makemytrip-logo.png" alt="MakeMyTrip" style={imgStyle} /> */}
                <p style={pStyle}>MakeMyTrip</p>
              </a>
              <a href="https://www.goibibo.com/bus/" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: 'rgb(34, 118, 227)' }}>
                {/* <img src="../../icon/goibibo-logo.png" alt="Goibibo" style={imgStyle} /> */}
                <p style={pStyle}>Goibibo</p>
              </a>
            </div>

            <h3>Train Booking Apps</h3>
            <div className="payment-container">
              <a href="https://www.irctc.co.in" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#2f4676' }}>
                {/* <img src="../../icon/irctc-logo.png" alt="IRCTC" style={imgStyle} /> */}
                <p style={pStyle}>IRCTC</p>
              </a>
              <a href="https://www.cleartrip.com/trains" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ffdead' }}>
                {/* <img src="../../icon/cleartrip-logo.png" alt="Cleartrip" style={imgStyle} /> */}
                <p style={pStyle}>Cleartrip</p>
              </a>
              <a href="https://www.makemytrip.com/railways/" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: 'rgb(255, 87, 51)' }}>
                {/* <img src="../../icon/makemytrip-logo.png" alt="MakeMyTrip" style={imgStyle} /> */}
                <p style={pStyle}>MakeMyTrip</p>
              </a>
              <a href="https://www.paytm.com/train-tickets" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: 'rgb(0, 186, 242)' }}>
                {/* <img src="../../icon/paytm-logo.png" alt="Paytm" style={imgStyle} /> */}
                <p style={pStyle}>Paytm</p>
              </a>
            </div>

            <h3>Movie Booking Apps</h3>
            <div className="payment-container">
              <a href="https://www.bookmyshow.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#f88371' }}>
                {/* <img src="../../icon/bookmyshow-logo.png" alt="BookMyShow" style={imgStyle} /> */}
                <p style={pStyle}>BookMyShow</p>
              </a>
              <a href="https://www.paytm.com/movies" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#f88379' }}>
                {/* <img src="../../icon/paytm-logo.png" alt="Paytm Movies" style={imgStyle} /> */}
                <p style={pStyle}>Paytm Movies</p>
              </a>
              <a href="https://www.ticketnew.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: 'rgb(0, 186, 242)' }}>
                {/* <img src="../../icon/ticketnew-logo.png" alt="TicketNew" style={imgStyle} /> */}
                <p style={pStyle}>TicketNew</p>
              </a>
              <a href="https://in.bookmyshow.com/explore/movies-hyderabad" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#db7093' }}>
                {/* <img src="../../icon/bookmyshow-logo.png" alt="BookMyShow" style={imgStyle} /> */}
                <p style={pStyle}>BookMyShow</p>
              </a>
            </div>

            <h3>Weekend trips and Adventure Booking Apps</h3>
            <div className="payment-container">
              <a href="https://www.makemytrip.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: 'rgb(255, 87, 51)' }}>
                {/* <img src="../../icon/makemytrip-logo.png" alt="MakeMyTrip" style={imgStyle} /> */}
                <p style={pStyle}>MakeMyTrip</p>
              </a>
              <a href="https://www.yatra.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ffe4b5' }}>
                {/* <img src="../../icon/yatra-logo.png" alt="Yatra" style={imgStyle} /> */}
                <p style={pStyle}>Yatra</p>
              </a>
              <a href="https://www.thrillophilia.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ffdab9' }}>
                {/* <img src="../../icon/thrillophilia-logo.png" alt="Thrillophilia" style={imgStyle} /> */}
                <p style={pStyle}>Thrillophilia</p>
              </a>
              <a href="https://traveltriangle.com/" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#eee8aa' }}>
                {/* <img src="../../icon/coxandkings-logo.jpg" alt="Travel Triangle" style={imgStyle} /> */}
                <p style={pStyle}>Travel Triangle</p>
              </a>
              <a href="https://www.adventurenation.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#fafad2' }}>
                {/* <img src="../../icon/adventurenation-logo.png" alt="Adventure Nation" style={imgStyle} /> */}
                <p style={pStyle}>Adventure Nation</p>
              </a>
              <a href="https://www.trekmunk.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#f0e68c' }}>
                {/* <img src="../../icon/trekmunk-logo.png" alt="Trekmunk" style={imgStyle} /> */}
                <p style={pStyle}>Trekmunk</p>
              </a>
              <a href="https://www.tripadvisor.in/Attraction_Review-g304554-d19415293-Reviews-The_Great_Next-Mumbai_Maharashtra.html" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#e6e6fa' }}>
                {/* <img src="../../icon/thegreatnext-logo.png" alt="The Great Next" style={imgStyle} /> */}
                <p style={pStyle}>The Great Next</p>
              </a>
              <a href="https://www.indiahikes.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#b0e0e6' }}>
                {/* <img src="../../icon/indiahikes-logo.png" alt="Indiahikes" style={imgStyle} /> */}
                <p style={pStyle}>Indiahikes</p>
              </a>
            </div>

            <h3>Low cost Abroad Trips Booking Apps</h3>
            <div className="payment-container">
              <a href="https://www.skyscanner.net" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#afeeee' }}>
                {/* <img src="../../icon/skyscanner-logo.png" alt="Skyscanner" style={imgStyle} /> */}
                <p style={pStyle}>Skyscanner</p>
              </a>
              <a href="https://www.kiwi.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#b0e0e6' }}>
                {/* <img src="../../icon/kiwi-logo.png" alt="Kiwi.com" style={imgStyle} /> */}
                <p style={pStyle}>Kiwi.com</p>
              </a>
              <a href="https://www.momondo.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#add8e6' }}>
                {/* <img src="../../icon/momondo-logo.jpg" alt="Momondo" style={imgStyle} /> */}
                <p style={pStyle}>Momondo</p>
              </a>
              <a href="https://www.hopper.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#87ceeb' }}>
                {/* <img src="../../icon/hopper-logo.png" alt="Hopper" style={imgStyle} /> */}
                <p style={pStyle}>Hopper</p>
              </a>
              <a href="https://www.rome2rio.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#87cefa' }}>
                {/* <img src="../../icon/rome2rio-logo.png" alt="Rome2Rio" style={imgStyle} /> */}
                <p style={pStyle}>Rome2Rio</p>
              </a>
              <a href="https://www.kayak.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#4682b4' }}>
                {/* <img src="../../icon/kayak-logo.png" alt="Kayak" style={imgStyle} /> */}
                <p style={pStyle}>Kayak</p>
              </a>
              <a href="https://www.traveloka.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#6495ed' }}>
                {/* <img src="../../icon/traveloka-logo.jpg" alt="Traveloka" style={imgStyle} /> */}
                <p style={pStyle}>Traveloka</p>
              </a>
              <a href="https://www.omio.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#5f9ea0' }}>
                {/* <img src="../../icon/omio-logo.png" alt="Omio" style={imgStyle} /> */}
                <p style={pStyle}>Omio</p>
              </a>
            </div>

            <h3>Cab Booking Apps</h3>
            <div className="payment-container">
              <a href="https://www.uber.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#48d1cc' }}>
                {/* <img src="../../icon/uber-logo.png" alt="Uber" style={imgStyle} /> */}
                <p style={pStyle}>Uber</p>
              </a>
              <a href="https://www.lyft.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#40e0d0' }}>
                {/* <img src="../../icon/lyft-logo.png" alt="Lyft" style={imgStyle} /> */}
                <p style={pStyle}>Lyft</p>
              </a>
              <a href="https://www.olacabs.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#00ced1' }}>
                {/* <img src="../../icon/ola-logo.jpg" alt="Ola" style={imgStyle} /> */}
                <p style={pStyle}>Ola</p>
              </a>
              <a href="https://www.grab.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#20b2aa' }}>
                {/* <img src="../../icon/grab-logo.png" alt="Grab" style={imgStyle} /> */}
                <p style={pStyle}>Grab</p>
              </a>
              <a href="https://bolt.eu" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#008b8b' }}>
                {/* <img src="../../icon/bolt-logo.png" alt="Bolt" style={imgStyle} /> */}
                <p style={pStyle}>Bolt</p>
              </a>
              <a href="https://www.gett.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#008080' }}>
                {/* <img src="../../icon/gett-logo.png" alt="Gett" style={imgStyle} /> */}
                <p style={pStyle}>Gett</p>
              </a>
              <a href="https://gocurb.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#00fa9a' }}>
                {/* <img src="../../icon/curb-logo.png" alt="Curb" style={imgStyle} /> */}
                <p style={pStyle}>Curb</p>
              </a>
              <a href="https://www.didiglobal.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#00ff7f' }}>
                {/* <img src="../../icon/didi-logo.png" alt="Didi" style={imgStyle} /> */}
                <p style={pStyle}>Didi</p>
              </a>
            </div>

            <h3>Food Booking Apps</h3>
            <div className="payment-container">
              <a href="https://www.grubhub.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#32cd32' }}>
                {/* <img src="../../icon/grubhub-logo.png" alt="Grubhub" style={imgStyle} /> */}
                <p style={pStyle}>Grubhub</p>
              </a>
              <a href="https://www.doordash.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#7cfc00' }}>
                {/* <img src="../../icon/doordash-logo.png" alt="DoorDash" style={imgStyle} /> */}
                <p style={pStyle}>DoorDash</p>
              </a>
              <a href="https://www.postmates.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#7fff00' }}>
                {/* <img src="../../icon/postmates-logo.jpg" alt="Postmates" style={imgStyle} /> */}
                <p style={pStyle}>Postmates</p>
              </a>
              <a href="https://www.swiggy.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#adff2f' }}>
                {/* <img src="../../icon/swiggy-logo.png" alt="Swiggy" style={imgStyle} /> */}
                <p style={pStyle}>Swiggy</p>
              </a>
              <a href="https://www.zomato.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#9acd32' }}>
                {/* <img src="../../icon/zomato-logo.png" alt="Zomato" style={imgStyle} /> */}
                <p style={pStyle}>Zomato</p>
              </a>
              <a href="https://www.deliveroo.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#556b2f' }}>
                {/* <img src="../../icon/deliveroo-logo.png" alt="Deliveroo" style={imgStyle} /> */}
                <p style={pStyle}>Deliveroo</p>
              </a>
              <a href="https://www.just-eat.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#6b8e23' }}>
                {/* <img src="../../icon/just-eat-logo.png" alt="Just Eat" style={imgStyle} /> */}
                <p style={pStyle}>Just Eat</p>
              </a>
              <a href="https://www.seamless.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#808000' }}>
                {/* <img src="../../icon/seamless-logo.png" alt="Seamless" style={imgStyle} /> */}
                <p style={pStyle}>Seamless</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingApps;

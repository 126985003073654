// CompanyRegistrationSuccess.js

import React from 'react';
import Header from '../common/Header'; // Adjust the import path as needed
import Footer from '../common/Footer'; // Adjust the import path as needed

const CompanyRegistrationSuccess = () => {
  return (
    <div>
      <Header />
      <div className="success-container">
        <div id="success-message">
          Registration successful! Click <a href="/employer/login">here</a> to login.
        </div>
      </div>
      <Footer />

      <style jsx>{`
        .success-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(100vh - 100px); /* Full height minus the height of header and footer */
          background-color: #f0f8ff; /* Light background color */
          border: 1px solid #ccc; /* Border color */
          padding: 20px;
          box-sizing: border-box;
        }

        #success-message {
          color: green;
          text-align: center;
          padding: 20px;
          background-color: #e0ffe0; /* Light green background */
          border: 1px solid green;
          border-radius: 5px;
          font-size: 25px;
        }
      `}</style>
    </div>
  );
}

export default CompanyRegistrationSuccess;

import React from 'react';
import '../../../styles/store.css';
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea'; // Import ProfileArea component

function LoanApp() {
  // const linkStyle = {
  //   textDecoration: 'none',
  //   color: 'inherit', // Inherit color from parent to ensure it matches with the <p> tag
  // };

  // const pStyle = {
  //   color: 'black',
  //   textDecoration: 'none',
  // };

  // const imgStyle = {
  //   width: '100px', // Set the width of the logo
  //   height: '100px', // Set the height of the logo
  //   objectFit: 'contain', // Ensure the logo maintains its aspect ratio
  // };

  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    color: 'white',
    fontSize: '16px',
    margin: '10px',
    padding: '10px'
  };
  
  const pStyle = {
    margin: '10px 0 0 0',
    fontWeight: 'bold'
  };

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          <h3>Home Loans</h3>
            <div className="payment-container">
              <a href="https://www.axisbank.com/retail/loans/personal-loan" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#e53935' }}>
                {/* <img src="../../icon/axisbank-logo.png" alt="Axis Bank" style={imgStyle} /> */}
                <p style={pStyle}>Axis Bank</p>
              </a>
              <a href="https://www.sbi.co.in/web/personal-banking/loans/personal-loans" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#1a237e' }}>
                {/* <img src="../../icon/sbi-logo.png" alt="State Bank of India" style={imgStyle} /> */}
                <p style={pStyle}>State Bank of India</p>
              </a>
              <a href="https://www.hdfcbank.com/personal/borrow/popular-loans/home-loan" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ff6f00' }}>
                {/* <img src="../../icon/hdfc-logo.png" alt="HDFC Bank" style={imgStyle} /> */}
                <p style={pStyle}>HDFC Bank</p>
              </a>
              <a href="https://www.hdbfs.com/personal-loan" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#0288d1' }}>
                {/* <img src="../../icon/hdb-logo.png" alt="HDB Financial Services" style={imgStyle} /> */}
                <p style={pStyle}>HDB Financial Services</p>
              </a>
            </div>
            <h3>Personal Loans</h3>
            <div className="payment-container">
              <a href="https://www.smfgindiacredit.com/personal-loan.aspx" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#00796b' }}>
                {/* <img src="../../icon/fullerton-logo.png" alt="SMFG India" style={imgStyle} /> */}
                <p style={pStyle}>SMFG India</p>
              </a>
              <a href="https://www.idbi.com/personal-loans.asp" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#4caf50' }}>
                {/* <img src="../../icon/idbi-logo.jpg" alt="IDBI Bank" style={imgStyle} /> */}
                <p style={pStyle}>IDBI Bank</p>
              </a>
              <a href="https://www.bajajfinserv.in/personal-loan" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#e65100' }}>
                {/* <img src="../../icon/bajaj-logo.png" alt="Bajaj Finance" style={imgStyle} /> */}
                <p style={pStyle}>Bajaj Finance</p>
              </a>
              <a href="https://www.hdfcbank.com/personal/borrow/popular-loans/home-loan" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ff6f00' }}>
                {/* <img src="../../icon/hdfc-logo.png" alt="HDFC Bank" style={imgStyle} /> */}
                <p style={pStyle}>HDFC Bank</p>
              </a>
            </div>
        </div>
      </div>
    </div>
  );
}

export default LoanApp;

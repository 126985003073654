import React from 'react';
import '../../../styles/store.css';
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea'; // Import ProfileArea component

function LoanApp() {
  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    color: 'white',
    fontSize: '16px',
    margin: '10px',
    padding: '10px'
  };
  
  const pStyle = {
    margin: '10px 0 0 0',
    fontWeight: 'bold'
  };

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          <div className="payment-container">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#4267B2' }}>
              {/* <img src="../../icon/fb-logo.png" alt="Facebook" style={imgStyle} /> */}
              <p style={pStyle}>Facebook</p>
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#C13584' }}>
              {/* <img src="../../icon/insta-logo.png" alt="Instagram" style={imgStyle} /> */}
              <p style={pStyle}>Instagram</p>
            </a>
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#FF0000' }}>
              {/* <img src="../../icon/you-tube-logo.png" alt="YouTube" style={imgStyle} /> */}
              <p style={pStyle}>YouTube</p>
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#0077B5' }}>
              {/* <img src="../../icon/LinkedIn_logo.png" alt="LinkedIn" style={imgStyle} /> */}
              <p style={pStyle}>LinkedIn</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoanApp;

import React, { useState, useEffect } from 'react';
import { BiFilter, BiSearch, BiX } from 'react-icons/bi';
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea';
import { BASE_URL } from '../../../utils/api';
import axios from 'axios';

function JobForYou({ job }) {
  const [showNotification, setShowNotification] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [appliedJobId, setAppliedJobId] = useState(null);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  useEffect(() => {
    const fetchJobs = async () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('id');

      try {
        const response = await fetch(`${BASE_URL}/api/job/foryou?user_id=${userId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setSearchResults(data.data);
          setNoDataFound(data.data.length === 0);
        } else {
          setSearchResults([]);
          setNoDataFound(true);
        }
      } catch (error) {
        console.error('Error fetching jobs:', error);
        setNoDataFound(true);
      }
    };

    fetchJobs();
  }, []);

  const formatText = (text) => {
    return text.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const handleApply = async (jobId) => {
    setIsSending(true);
    const token = localStorage.getItem('token');
    const jobSeekerId = localStorage.getItem('id');

    try {
      const response = await axios.post(
        `${BASE_URL}/api/apply/job`,
        {
          job_posting_id: jobId,
          job_seeker_id: jobSeekerId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.message === 'Application sent successfully') {
        setAppliedJobId(jobId);
        setIsEmailSent(true);
      }
    } catch (error) {
      console.error('Error applying for job:', error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea showNotification={showNotification} toggleNotification={toggleNotification} profileImage={profileImage} />
          <hr />

          {/* Display search results or no data found message */}
          {noDataFound ? (
            <p style={{color:'red', fontSize:'19px', textAlign:'center', marginTop:'3em'}}>No job found for your location</p>
          ) : (
            searchResults.map(job => (
              <div className="profile-card" key={job.id}>
                <div className="job-container">
                <img src="/icon/namsbel-logo.png" alt="Profile Photo" className="profile-photo" width="150" height="150" />
                  <div className="job-details">
                    <h3>{job.job_title}</h3>
                    <h4>{job.title}</h4>
                    <h4>{job.company_name}</h4>
                    <p>Industry: {formatText(job.job_industry)}</p>
                    <p>{job.state}</p>
                    <p>{job.city}</p>
                    <p>Posted {new Date(job.created_at).toLocaleDateString()}</p>
                  </div>
                  <div className="job-description-skills">
                    <h4>Job Description</h4>
                    <p>{job.job_description}</p>
                    <h4>Skills</h4>
                    <p>{job.job_skills}</p>
                    <h4>Positions</h4>
                    <p>{job.number_of_positions}</p>

                    {appliedJobId === job.id && (
                      <p style={{ color: 'green' }}>Application Sent Successfully</p>
                    )}
                    <button
                      className="btn-download"
                      onClick={() => handleApply(job.id)}
                      disabled={appliedJobId === job.id && isEmailSent}
                    >
                      {isSending ? 'Sending Application...' : isEmailSent ? 'Application Sent' : 'Apply now'}
                    </button>
                    {isSending && <div className="loader"></div>}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default JobForYou;

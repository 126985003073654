import React, {useState } from 'react';
import Header from '../common/Header'; // Adjusted import path
import Footer from '../common/Footer'; // Adjusted import path

function Aboutus() {
  const [showNotification, setShowNotification] = useState(false);
  
  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      color: '#333',
    },
    mainContent: {
      backgroundColor: '#f9f9f9',
      padding: '30px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    profileText: {
      fontSize: '2em',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    aboutContent: {
      lineHeight: '1.6',
      fontSize: '1em',
    },
    paragraph: {
      marginBottom: '20px',
    },
    orderedList: {
      paddingLeft: '20px',
      marginBottom: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    listItemStrong: {
      fontWeight: 'bold',
      color: '#2c3e50',
    },
    hr: {
      border: 0,
      height: '1px',
      background: '#ccc',
      marginBottom: '20px',
    }
  };
  
  return (
    <div>
      <Header />
      <div style={styles.container}>
        <div style={styles.mainContent} id="main-content">
          <div style={styles.profileText} id="profile-heading">About Us</div>
          <hr style={styles.hr} />
          <div style={styles.aboutContent}>
            <p style={styles.paragraph}>Welcome to PromoterPal!</p>
            <p style={styles.paragraph}>
              At Promoter Pal, we're dedicated to revolutionizing the way sales professionals work and grow. 
              Our mission is to provide a comprehensive platform that empowers sales promoters to excel in 
              their careers, enhance their skills, and improve their overall quality of life. Here's how 
              Promoter Pal can make a difference for you:
            </p>
            <ol style={styles.orderedList}>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Explore New Job Opportunities:</strong> Tired of feeling stuck in the same sales job? 
                With Promoter Pal, you can easily search and apply for new job opportunities based on your location 
                and preferences. Find the perfect fit for your skills and aspirations, and take control of your career path.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Embrace Cutting-Edge Sales Technologies:</strong> Adapt to the latest sales technologies with ease. 
                Promoter Pal offers tools and resources that help you sell products at higher prices and increase your 
                sales performance. Stay ahead in the competitive retail environment by leveraging innovative sales strategies.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Update Your Store Location:</strong> Keep your store location information up to date with Promoter Pal. 
                This feature ensures that your current workplace details are always accurate, making it easier for you to manage 
                your work environment and for employers to find you.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Enhance Your Skills with Training Sessions:</strong> Boost your sales and training skills with our 
                comprehensive training sessions. Promoter Pal offers a variety of courses designed to enhance your professional 
                development and ensure you stay at the top of your game.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Connect to PF and ESIC Portals:</strong> Easily access your Provident Fund (PF) and Employee State Insurance 
                Corporation (ESIC) details through Promoter Pal. Manage your benefits and stay informed about your financial and 
                healthcare entitlements with just a few clicks.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Pay Bills Conveniently:</strong> Say goodbye to juggling multiple platforms for bill payments. Promoter Pal 
                allows you to pay your bills directly through our platform, saving you time and hassle.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Find Nearby Hospitals:</strong> In need of quick medical attention? Promoter Pal helps you locate nearby hospitals, 
                ensuring you can access medical care swiftly when needed.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Access Emergency Services:</strong> Promoter Pal provides a direct link to emergency services, allowing for a rapid 
                response in critical situations. Your safety and well-being are our priority.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Connect to Financial Services:</strong> Simplify the process of securing loans and credit cards by connecting to 
                relevant financial apps through Promoter Pal. Manage your finances efficiently and access the services you need with ease.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Stay Informed with Our News Feed:</strong> Stay ahead of the curve with Promoter Pal's news feed. Our blog keeps 
                you updated on the latest industry trends, tips, and news, ensuring you're always in the know.
              </li>
            </ol>
            <p style={styles.paragraph}>
              At Promoter Pal, we believe in empowering sales professionals to achieve their fullest potential. Join us today and experience 
              the many benefits our platform has to offer. Revolutionize your sales career with Promoter Pal!
            </p>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  );
}

export default Aboutus;

import React, { useState } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import LogoutButton from './LogoutButton'; // Adjust the path as needed


function getProfileHeading(location) {
  if (location.pathname === '/promoter/dashboard') {
    return 'Dashboard';
  } else if (location.pathname === '/employer/dashboard') {
    return 'Dashboard';
  } else if(location.pathname === '/promoter/dashboard/jobsearch') {
    // Add more else if conditions for other URLs if needed
    return 'Job Search'; // Default to "Profile" for other URLs
  }else if(location.pathname === '/promoter/dashboard/jobforyou') {
    // Add more else if conditions for other URLs if needed
    return 'Job For You'; // Default to "Profile" for other URLs
  }else if(location.pathname === '/promoter/dashboard/store') {
    // Add more else if conditions for other URLs if needed
    return 'Update Current Store'; // Default to "Profile" for other URLs
  }else if(location.pathname === '/promoter/dashboard/training-and-technology') {
    // Add more else if conditions for other URLs if needed
    return 'Training And Technology'; // Default to "Profile" for other URLs
  }else if(location.pathname === '/promoter/dashboard/promoter-training-videos') {
    // Add more else if conditions for other URLs if needed
    return 'Our Training Blog'; // Default to "Profile" for other URLs
  }else if(location.pathname === '/promoter/dashboard/pf-and-esic') {
    // Add more else if conditions for other URLs if needed
    return 'PF And ESIC'; // Default to "Profile" for other URLs
  }else if(location.pathname === '/promoter/dashboard/booking-and-finance') {
    // Add more else if conditions for other URLs if needed
    return 'Pay Bills & Recharge'; // Default to "Profile" for other URLs
  }else if(location.pathname === '/promoter/dashboard/hospital-locator') {
    // Add more else if conditions for other URLs if needed
    return 'Hospital Locator'; // Default to "Profile" for other URLs
  }else if(location.pathname === '/promoter/dashboard/emerency-service') {
    // Add more else if conditions for other URLs if needed
    return 'Emergency Service'; // Default to "Profile" for other URLs
  }else if(location.pathname === '/promoter/dashboard/news-community-posting') {
    // Add more else if conditions for other URLs if needed
    return 'News/Community Posting';
  }else if(location.pathname === '/promoter/dashboard/travel-apps') {
    // Add more else if conditions for other URLs if needed
    return 'Travel Apps';
  }else if(location.pathname === '/promoter/dashboard/loans-apps') {
    // Add more else if conditions for other URLs if needed
    return 'Home & Personal Loan';
  }else if(location.pathname === '/promoter/dashboard/social-media-apps') {
    // Add more else if conditions for other URLs if needed
    return 'Social Media';
  }else if(location.pathname === '/promoter/dashboard/health-insurance') {
    // Add more else if conditions for other URLs if needed
    return 'Health Insurance';
  }else if(location.pathname === '/promoter/dashboard/booking-apps') {
    // Add more else if conditions for other URLs if needed
    return 'Air/Bus/Train/Movie Booking apps';
  }else if(location.pathname === '/promoter/dashboard/credit-card-apps') {
    // Add more else if conditions for other URLs if needed
    return 'Credit Card Apps';
  }else if(location.pathname === '/employer/dashboard/employee-search') {
    // Add more else if conditions for other URLs if needed
    return 'Employee Search';
  }else if(location.pathname === '/employer/dashboard/labour-law') {
    // Add more else if conditions for other URLs if needed
    return 'Labour Law';
  }else if(location.pathname === '/employer/dashboard/min-wages') {
    // Add more else if conditions for other URLs if needed
    return 'Min Wages';
  }else if(location.pathname === '/employer/dashboard/training-blog') {
    // Add more else if conditions for other URLs if needed
    return 'Our Trainings on Blog';
  }else if(location.pathname === '/employer/dashboard/store-locator') {
    // Add more else if conditions for other URLs if needed
    return 'Update Current Store';
  }else if(location.pathname === '/employer/dashboard/job-post') {
    // Add more else if conditions for other URLs if needed
    return 'Job Post';
  }else{
     return 'Profile';
  }
}


function ProfileArea({ showNotification, toggleNotification, profileImage, toggleSidebar }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const userName = localStorage.getItem('userName');
  const location = useLocation();
  const navigate = useNavigate();
  const roleId = localStorage.getItem('role_id');

  const headingText = getProfileHeading(location);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleProfileClick = () => {
    if (roleId === '3') {
      navigate('/promoter/dashboard/editprofile');
    } else if (roleId === '2') {
      navigate('/employer/dashboard/editprofile');
    }
  };


  return (
    <div className="profile-area">
      <div className="profile-text" id="profile-heading">{headingText}</div>
      <button onClick={handleProfileClick} className="profile-button" style={{marginRight:"4px", background:"red", color:"#fff", borderRadius:'5px'}}>Profile</button>
      {/* <div className="notification">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" fill="currentColor" className="bi bi-bell-fill" viewBox="0 0 16 16" onClick={toggleNotification}>
          <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
        </svg>
        {showNotification && <div className="notification-popup">Notifications will appear here when you hover over the icon.</div>}
      </div> */}
      <img src={profileImage} alt="Profile Image" onClick={toggleDropdown} />
      <div className={`dropdown-menu ${showDropdown ? 'show' : ''}`}>
        <LogoutButton />
      </div>
      {/* Hamburger menu icon for toggling sidebar */}
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-list sidebar-button" viewBox="0 0 16 16" onClick={toggleSidebar}>
        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5.5 0 0 1-.5-.5"/>
      </svg>
    </div>
  );
}

export default ProfileArea;

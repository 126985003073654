import React, { useState } from 'react';
import '../../../styles/jobsearch.css';
import EmployerSidebar from '../../common/EmployerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea';

function StoreLocator() {
  const [showNotification, setShowNotification] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("SJCE STEP Office, JC college, Mysore, Karnataka");
  const [message, setMessage] = useState("");

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  const handleChange = (event) => {
    setCurrentLocation(event.target.value);
  };

  const handleSubmit = () => {
    setMessage("Location updated successfully!");
  };

  return (
    <div>
      <Header />
      <div className="container">
        <EmployerSidebar />
        <div className="main-content" id="main-content-1">
          <ProfileArea showNotification={showNotification} toggleNotification={toggleNotification} profileImage={profileImage} />
          <hr />
          <div class="container-lcator">
            <label htmlFor="currentLocation" className='str-lbl'>Current Location</label>
            <input
              type="text"
              id="currentLocation"
              value={currentLocation}
              onChange={handleChange}
            />
            <div id="message">{message}</div>
            <button className="button-str-lcn" onClick={handleSubmit}>Update Location</button>
        </div>
        </div>
      </div>
    </div>
  );
}

export default StoreLocator;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import axios from 'axios';
import { BASE_URL } from '../../utils/api';
import Header from '../common/Header';
import Footer from '../common/Footer';
import '../../styles/registration.css';

function JobSeekerRegistration() {
  const navigate = useNavigate(); // Initialize useNavigate

  const [email, setEmail] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    gender: '',
    location: '',
    date_of_birth: '',
    country_code: '',
    phone_number: '',
    industry: '',
    experience: '',
    current_salary: '',
    address: ''
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      const storedEmail = localStorage.getItem('userEmail');
      if (storedEmail) {
        setEmail(storedEmail);
      } else {
        // Handle case when email is not found
      }
    }, 1000); // Delay in milliseconds (adjust as needed)

    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/api/registerJobSeeker`, formData);
      if (response.data && response.data.message) {
        localStorage.removeItem('userEmail');
        setRegistrationSuccess(true);
        // Redirect to success page after successful registration
        navigate("/promoter/registerSuccess");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = Object.values(error.response.data.errors).flat();
        setErrorMessages(errors);
      } else {
        setErrorMessages(['Registration failed. Please try again.']);
      }
    }
  };

  return (
    <div>
      <Header />
      <h2 className='reg-form'>Promoter Registration</h2>
      <div className="form-container-reg">
        <form className="registration-form" onSubmit={handleSubmit}>
          <div className="form-field">
            <input type="text" id="name" name="full_name" placeholder="Name" value={formData.full_name} onChange={(e) => setFormData({ ...formData, full_name: e.target.value })} required />
          </div>
          <div className="form-field required">
            <input type="email" id="email" name="email" placeholder="Email ID*" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
          </div>
          <div className="form-field gender-input">
            <select id="gender" name="gender" value={formData.gender} onChange={(e) => setFormData({ ...formData, gender: e.target.value })} required>
                <option value="">Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="transgender">Transgender</option>
                <option value="other">Other</option>
            </select>
          </div>
          <div className="form-field location-input">
            <input type="text" id="location" name="location" placeholder="Location" value={formData.location} onChange={(e) => setFormData({ ...formData, location: e.target.value })} />
          </div>
          <div className="form-field date-input">
            <input type="date" id="year" name="date_of_birth" placeholder="Date Of Birth" value={formData.date_of_birth} onChange={(e) => setFormData({ ...formData, date_of_birth: e.target.value })} />
          </div>
          <div className="form-field countrycode-input">
            <input type="text" id="country_code" name="country_code" placeholder="Country Code" value={formData.country_code} onChange={(e) => setFormData({ ...formData, country_code: e.target.value })} />
          </div>
          <div className="form-field">
            <input type="text" id="phone" name="phone_number" placeholder="Phone Number" value={formData.phone_number} onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })} />
          </div>
          <div className="form-field">
          <select
              id="industry"
              name="industry"
              value={formData.industry}
              onChange={(e) => setFormData({ ...formData, industry: e.target.value })}
              required
            >
              <option value="">Select Industry</option>
              <option value="consumer-durables">Consumer Durables</option>
              <option value="telecom-mobile-phone">Telecom Mobile Phone</option>
              <option value="large-retail">Large Retail</option>
              <option value="automobiles">Automobiles</option>
              <option value="shoes-leather">Shoes & Leather</option>
              <option value="garments">Garments</option>
              <option value="jewellery-pens-watches">Jewellery, Pens & Watches</option>
              <option value="fmcg">FMCG</option>
              <option value="medical">Medical</option>
              <option value="hardware-sanitary">Hardware and Sanitary</option>
              <option value="sports-health">Sports & Health</option>
              <option value="books-education">Books & Education</option>
              <option value="banking-insurance">Banking & Insurance</option>
              <option value="real-estate">Real-estate</option>
              <option value="furniture">Furniture</option>
              <option value="others-specify">Others (Specify)</option>
            </select>
          </div>
          <div className="form-field experiance-input">
            <input type="text" id="experiance" name="experience" placeholder="Year Of Exp" value={formData.experience} onChange={(e) => setFormData({ ...formData, experience: e.target.value })} />
          </div>
          <div className="form-field package-input">
            <input type="text" id="package" name="current_salary" placeholder="Current Salary (in LPA)" value={formData.current_salary} onChange={(e) => setFormData({ ...formData, current_salary: e.target.value })} />
          </div>
          <div className="form-field">
            <textarea id="address" name="address" placeholder="Address" value={formData.address} onChange={(e) => setFormData({ ...formData, address: e.target.value })}></textarea>
          </div>
          <button type="submit" className="form-button">Register</button>
        </form>
      </div>
      {errorMessages.length > 0 && (
        <div style={{ color: 'red' }}>
          <h3>Error:</h3>
          <ul>
            {errorMessages.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default JobSeekerRegistration;

import React, {useEffect, useState } from 'react';
import { BASE_URL } from '../../../utils/api'; // Adjust the path as needed
import DataTable from 'react-data-table-component';



function TabContainer() {
  const [activeTab, setActiveTab] = useState('videos');

  const changeTab = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div>
      <div className="tab-buttons">
        <div className={`tab ${activeTab === 'videos' ? 'active' : ''}`} onClick={() => changeTab('videos')}>
          Videos
        </div>
        <div className={`tab ${activeTab === 'presentation' ? 'active' : ''}`} onClick={() => changeTab('presentation')}>
          Presentation
        </div>
        <div className={`tab ${activeTab === 'articles' ? 'active' : ''}`} onClick={() => changeTab('articles')}>
          Articles
        </div>
      </div>

     
      <div id="videos" className={`content-container ${activeTab === 'videos' ? 'active' : ''}`}>
        {/* Boxes for Videos */}
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
      </div>

      <div id="presentation" className={`content-container ${activeTab === 'presentation' ? 'active' : ''}`}>
        {/* Boxes for Presentation */}
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe> </div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe> </div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe> </div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe> </div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe> </div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe> </div>
      </div>

      <div id="articles" className={`content-container ${activeTab === 'articles' ? 'active' : ''}`}>
        {/* Boxes for Articles */}
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
        <div className="box"><iframe title="Video 1" width="250" height="315" src="https://www.youtube.com/embed/VIDEO_ID_1" frameborder="0" allowfullscreen></iframe></div>
      </div>
    </div>
  );
}

export default TabContainer;

import React, { useState } from 'react';
import '../../../styles/jobsearch.css';
import EmployerSidebar from '../../common/EmployerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea';

function EmployeeSearchResult() {
  const [showNotification, setShowNotification] = useState(false);

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  return (
    <div>
      <Header />
      <div className="container">
        <EmployerSidebar />
        <div className="main-content" id="main-content-1">
          <ProfileArea showNotification={showNotification} toggleNotification={toggleNotification} profileImage={profileImage} />
          <hr />

          <div className="profile-card">
            <img src="../../icon/profile-pic.jpg"  className="profile-photo" width="150" height="150" alt="Profile Photo"/>
            <div className="profile-details">
            <h3>Minumithra GN</h3>
            <p>UX/UI Designer</p>
            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit.</p>
            <a href="resume_url.pdf" className="btn-download" download>Download Resume</a>
            </div>
        </div>
          
        </div>
      </div>
    </div>
  );
}

export default EmployeeSearchResult;

import React, { useState } from 'react';
import '../../../styles/jobsearch.css';
import EmployerSidebar from '../../common/EmployerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea';
import { useNavigate } from 'react-router-dom';

function HighlightedText({ text, fontSize }) {
  return <p style={{ color: 'red', fontSize }}>{text}</p>;
}

function SalesIncentivePayout({ history }) {
  const [showNotification, setShowNotification] = useState(false);

  const navigate = useNavigate();

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  return (
    <div>
      <Header />
      <div className="container">
        <EmployerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea showNotification={showNotification} toggleNotification={toggleNotification} profileImage={profileImage} />
          <hr />
          <div className="message-container">
            <HighlightedText text="If you are interested in making an incentive for your promoters. Please let us know. We will create the mechanism for your system." fontSize="18px" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesIncentivePayout;

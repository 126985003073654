import React, { useState } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import axios from 'axios';
import { BASE_URL } from '../../utils/api';

function Contactus() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/api/contact`, formData);
      console.log('Form data submitted:', response.data);
      setIsSubmitted(true);
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('An error occurred while submitting the form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Your existing styles constant
  const styles = {
    container: {
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "20px",
      fontFamily: "Arial, sans-serif",
      color: "#333",
    },
    mainContent: {
      backgroundColor: "#f9f9f9",
      padding: "30px",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    profileText: {
      fontSize: "2em",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    hr: {
      border: 0,
      height: "1px",
      background: "#ccc",
      marginBottom: "20px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    label: {
      marginBottom: "10px",
      fontWeight: "bold",
    },
    input: {
      marginBottom: "20px",
      padding: "10px",
      borderRadius: "4px",
      border: "1px solid #ccc",
      fontSize: "1em",
    },
    textarea: {
      marginBottom: "20px",
      padding: "10px",
      borderRadius: "4px",
      border: "1px solid #ccc",
      fontSize: "1em",
      height: "100px",
    },
    button: {
      padding: "10px 20px",
      borderRadius: "4px",
      border: "none",
      backgroundColor: "#007bff",
      color: "#fff",
      fontSize: "1em",
      cursor: "pointer",
    },
    buttonHover: {
      backgroundColor: "#0056b3",
    },
    successMessage: {
      color: "green",
      fontSize: "1em",
      marginTop: "20px",
    },
  };

  // New constant for loader styles
  const loaderStyles = {
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
    },
    spinner: {
      border: '5px solid #f3f3f3',
      borderTop: '5px solid #3498db',
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      animation: 'spin 1s linear infinite',
    }
  };

  return (
    <div>
      <Header />
      <div style={styles.container}>
        <div style={styles.mainContent} id="main-content">
          <div style={styles.profileText} id="profile-heading">Contact Us</div>
          <hr style={styles.hr} />
          <p>If you have any questions, feel free to reach out to us using the form below:</p>
          <form style={styles.form} onSubmit={handleSubmit}>
            <label style={styles.label} htmlFor="name">Name</label>
            <input
              style={styles.input}
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label style={styles.label} htmlFor="email">Email</label>
            <input
              style={styles.input}
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label style={styles.label} htmlFor="phone">Phone</label>
            <input
              style={styles.input}
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <label style={styles.label} htmlFor="message">Message</label>
            <textarea
              style={styles.textarea}
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <button
              type="submit"
              style={{...styles.button, ':hover': styles.buttonHover}}
              disabled={isLoading}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          </form>
          {isSubmitted && (
            <div style={styles.successMessage}>
              Thank you! Your message has been successfully submitted.
            </div>
          )}
          {error && (
            <div style={{...styles.successMessage, color: 'red'}}>
              {error}
            </div>
          )}
        </div>
        <Footer />
      </div>
      {isLoading && (
        <div style={loaderStyles.container}>
          <div style={loaderStyles.spinner}></div>
        </div>
      )}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
}

export default Contactus;
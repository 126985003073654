import React from 'react';
import '../../../styles/store.css';
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea'; // Import ProfileArea component

function TravelApps() {
  // const linkStyle = {
  //   textDecoration: 'none',
  //   color: 'inherit', // Inherit color from parent to ensure it matches with the <p> tag
  // };

  // const pStyle = {
  //   color: 'black',
  //   textDecoration: 'none',
  // };

  // const imgStyle = {
  //   width: '100px', // Fixed width for all logos
  //   height: '100px', // Fixed height for all logos
  //   objectFit: 'contain', // Ensure the images fit within the set dimensions
  //   marginBottom: '10px', // Space between the image and the text
  // };

  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    color: 'white',
    fontSize: '16px',
    margin: '10px'
  };
  
  const pStyle = {
    margin: '10px 0 0 0',
    fontWeight: 'bold'
  };
  
  const imgStyle = {
    width: '50px',
    height: '50px',
    marginBottom: '10px'
  };

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          <div className="payment-container">
            <a href="https://www.makemytrip.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ff5733' }}>
              {/* <img src="../../icon/makemytrip-logo.png" alt="MakeMyTrip" style={imgStyle} /> */}
              <p style={pStyle}>MakeMyTrip</p>
            </a>
            <a href="https://www.airbnb.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ff5a5f' }}>
              {/* <img src="../../icon/airbnb-logo.png" alt="Airbnb" style={imgStyle} /> */}
              <p style={pStyle}>Airbnb</p>
            </a>
            <a href="https://www.goibibo.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ff6f61' }}>
              {/* <img src="../../icon/goibibo-logo.png" alt="Goibibo" style={imgStyle} /> */}
              <p style={pStyle}>Goibibo</p>
            </a>
            <a href="https://www.expedia.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#0033a0' }}>
              {/* <img src="../../icon/expedia-logo.png" alt="Expedia" style={imgStyle} /> */}
              <p style={pStyle}>Expedia</p>
            </a>
            {/* Add more travel websites here */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TravelApps;

import React from 'react';
// import { Link } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';

function EmployerSidebar({ isOpen, toggleProfileForm, toggleSidebar }) {
  const handleLinkClick = () => {
    // Toggle profile form visibility when sidebar link is clicked
    toggleProfileForm();
    // Toggle sidebar visibility when sidebar link is clicked
    toggleSidebar();
  };
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <ul>
        <li>
          <NavLink to="/employer/dashboard/employee-search" className={({ isActive }) => isActive ? 'active' : ''}>
            Employee Search
          </NavLink>
        </li>
        <li>
          <NavLink to="/employer/dashboard/job-post" className={({ isActive }) => isActive ? 'active' : ''}>
            Job Post
          </NavLink>
        </li>
        <li>
          <NavLink to="/employer/dashboard/labour-law" className={({ isActive }) => isActive ? 'active' : ''}>
            Labour Law
          </NavLink>
        </li>
        <li>
          <NavLink to="/employer/dashboard/min-wages" className={({ isActive }) => isActive ? 'active' : ''}>
           Min Wages
          </NavLink>
        </li>
        <li>
          <NavLink to="/employer/dashboard/training-blog" className={({ isActive }) => isActive ? 'active' : ''}>
            Our Trainings on Blog
          </NavLink>
        </li>
        <li>
          <NavLink to="/employer/dashboard/store-locator" className={({ isActive }) => isActive ? 'active' : ''}>
          Store Locator
          </NavLink>
        </li>
        <li>
          <Link to="#">Employee Rating</Link>
        </li>
        <li>
          <Link to="#">Buy Awards/ Rewards</Link>
        </li>
        <li>
          <NavLink to="/employer/dashboard/sales-incentice-payout" className={({ isActive }) => isActive ? 'active' : ''}>
            Sales Incentive Pay-outs
          </NavLink>
        </li>
        <li>
          <Link to="#">Add Payroll To Employees</Link>
        </li>
      </ul>
    </div>
  );
}

export default EmployerSidebar;

import React, { useState, useEffect } from 'react';
import '../../../styles/main.css';
import EmployerSidebar from '../../common/EmployerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea';
import { BASE_URL } from '../../../utils/api'; // Adjust the path as needed
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';



function JobPost() {
  const [showNotification, setShowNotification] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [formData, setFormData] = useState({
    company_id: '',
    company_name: '',
    job_title: '',
    job_industry: '',
    gender: 'All',
    salary_from: '',
    salary_to: '',
    country: '',
    state: '',
    city: '',
    number_of_positions: '',
    min_experience: '',
    max_experience: '',
    job_description: '',
    job_skills: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [jobPostings, setJobPostings] = useState([]);

  const token = localStorage.getItem('token');
  const company_id = localStorage.getItem('id');

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { ...formData, company_id };

    try {
      const response = await axios.post(`${BASE_URL}/api/job-postings`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setSuccessMessage(response.data.message);
        setFormData({
          company_id: '',
          company_name: '',
          job_title: '',
          job_industry: '',
          gender: 'All',
          salary_from: '',
          salary_to: '',
          country: '',
          state: '',
          city: '',
          number_of_positions: '',
          min_experience: '',
          max_experience: '',
          job_description: '',
          job_skills: '',
        });
        window.scrollTo(0, 0);
        fetchJobPostings(); // Fetch job postings again after adding a new one
      }
    } catch (error) {
      console.error('Error creating job posting:', error);
      alert('An error occurred while creating the job posting');
    }
  };

  const fetchJobPostings = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/job-postings/${company_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setJobPostings(response.data.data); // Adjust based on your API response structure
    } catch (error) {
      console.error('Error fetching job postings:', error);
    }
  };

  useEffect(() => {
    fetchJobPostings();
  }, []);


  // Function to handle delete action
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api/job-postings-delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchJobPostings(); // Refresh the table after deletion
    } catch (error) {
      console.error('Error deleting job posting:', error);
    }
  };


// Define columns for the data table
const columns = [
  { name: 'Job Title', selector: row => row.job_title, sortable: true },
  { name: 'Job Industry', selector: row => row.job_industry, sortable: true },
  { name: 'Gender', selector: row => row.gender, sortable: true },
  { name: 'Salary From', selector: row => row.salary_from, sortable: true },
  // { name: 'Salary To', selector: row => row.salary_to, sortable: true },
  // { name: 'Country', selector: row => row.country, sortable: true },
  // { name: 'State', selector: row => row.state, sortable: true },
  { name: 'City', selector: row => row.city, sortable: true },
  { name: 'Number of Positions', selector: row => row.number_of_positions, sortable: true },
  { name: 'Min Experience', selector: row => row.min_experience, sortable: true },
  // { name: 'Max Experience', selector: row => row.max_experience, sortable: true },
  // { name: 'Job Description', selector: row => row.job_description, sortable: true },
  // { name: 'Job Skills', selector: row => row.job_skills, sortable: true },
  {
    name: 'Actions',
    cell: row => (
      <div>
          {/* <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(row)} className="action-icon edit-icon" /> */}
          <FontAwesomeIcon icon={faTrashAlt} onClick={() => handleDeleteConfirmation(row.id)} className="action-icon delete-icon" />
          {row.is_active 
            ? <FontAwesomeIcon icon={faToggleOn} onClick={() => handleToggleStatus(row)} className="action-icon active-icon" />
            : <FontAwesomeIcon icon={faToggleOff} onClick={() => handleToggleStatus(row)} className="action-icon inactive-icon" />
          }
        </div>
    ),
  },
];

// Function to handle delete confirmation
const handleDeleteConfirmation = (id) => {
  if (window.confirm('Are you sure you want to delete this job posting?')) {
    handleDelete(id);
  }
};


// // Function to handle edit action
// const handleEdit = (row) => {
//   // Implement logic to navigate to edit page or show modal for editing
// };


// Function to handle toggle status action
const handleToggleStatus = async (row) => {
  try {
    const updatedRow = { is_active: !row.is_active }; // Only send the updated field
    await axios.put(`${BASE_URL}/api/job-postings/${row.id}/update-status`, updatedRow, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    fetchJobPostings(); // Refresh the table after status update
  } catch (error) {
    console.error('Error toggling job status:', error);
  }
};



  return (
    <div>
      <Header />
      <div className="container">
        <EmployerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea showNotification={showNotification} toggleNotification={toggleNotification} profileImage={profileImage} />
          <hr />
          {successMessage && <div className="success-message-job">{successMessage}</div>}
          <form onSubmit={handleSubmit} className="job-post-form">
            <div className="form-group">
              <label>Company Name:</label>
              <input type="text" name="company_name" value={formData.company_name} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Job Title:</label>
              <input type="text" name="job_title" value={formData.job_title} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Job Industry:</label>
              <select name="job_industry" value={formData.job_industry} onChange={handleChange} required>
                <option value="">Select Industry</option>
                <option value="consumer-durables">Consumer Durables</option>
                <option value="telecom-mobile-phone">Telecom Mobile Phone</option>
                <option value="large-retail">Large Retail</option>
                <option value="automobiles">Automobiles</option>
                <option value="shoes-leather">Shoes & Leather</option>
                <option value="garments">Garments</option>
                <option value="jewellery-pens-watches">Jewellery, Pens & Watches</option>
                <option value="fmcg">FMCG</option>
                <option value="medical">Medical</option>
                <option value="hardware-sanitary">Hardware and Sanitary</option>
                <option value="sports-health">Sports & Health</option>
                <option value="books-education">Books & Education</option>
                <option value="banking-insurance">Banking & Insurance</option>
                <option value="real-estate">Real-estate</option>
                <option value="furniture">Furniture</option>
                <option value="others-specify">Others (Specify)</option>
              </select>
            </div>
            <div className="form-group">
              <label>Gender:</label>
              <select name="gender" value={formData.gender} onChange={handleChange} required>
                <option value="All">All</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Transgender">Transgender</option>
              </select>
            </div>
            <div className="form-group">
              <label>Salary From:</label>
              <input type="number" name="salary_from" value={formData.salary_from} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Salary To:</label>
              <input type="number" name="salary_to" value={formData.salary_to} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Country:</label>
              <input type="text" name="country" value={formData.country} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>State:</label>
              <input type="text" name="state" value={formData.state} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>City:</label>
              <input type="text" name="city" value={formData.city} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Number of Positions:</label>
              <input type="number" name="number_of_positions" value={formData.number_of_positions} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Minimum Experience (years):</label>
              <input type="number" name="min_experience" value={formData.min_experience} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Maximum Experience (years):</label>
              <input type="number" name="max_experience" value={formData.max_experience} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Job Description:</label>
              <textarea name="job_description" value={formData.job_description} onChange={handleChange} required></textarea>
            </div>
            <div className="form-group">
              <label>Job Skills:</label>
              <textarea name="job_skills" value={formData.job_skills} onChange={handleChange} required></textarea>
            </div>
            <button type="submit" className="btn-submit">Create Job Posting</button>
          </form>
          {jobPostings.length > 0 && (
            <DataTable
            columns={columns}
            data={jobPostings}
            pagination
            highlightOnHover
            pointerOnHover
            responsive
            striped
          />
          )}
        </div>
      </div>
    </div>
  );
}

export default JobPost;

import React, { useState } from 'react';
import '../../../styles/jobsearch.css';
import EmployerSidebar from '../../common/EmployerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea';
import { BASE_URL } from '../../../utils/api'; // Adjust the path as needed


function EmployeeSearch({ history }) {
  const [showNotification, setShowNotification] = useState(false);
  const [searchResultMessage, setSearchResultMessage] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchFormData, setSearchFormData] = useState({
    location: '',
    industry: '',
    minSalary: '',
    current_salary: '',
    minExperience: '',
    experience: ''
  });

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  const handleSearch = async () => {
    try {
      // Make the API call to search for employees
      const response = await fetch(`${BASE_URL}/api/employees/search`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Assuming access token is stored in localStorage
        },
        params: searchFormData
      });

      const responseData = await response.json();

      if (response.ok) {
        if (responseData.data.length === 0) {
          setSearchResultMessage('No employees found.');
        } else {
          setSearchResults(responseData.data);
          setSearchResultMessage('');
        }
      } else {
        // If there is an error, display an error message
        setSearchResultMessage('Error searching employees');
      }
    } catch (error) {
      console.error('Error searching employees:', error);
      // Display an error message
      setSearchResultMessage('Error searching employees');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchFormData({ ...searchFormData, [name]: value });
  };

  return (
    <div>
      <Header />
      <div className="container">
        <EmployerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea showNotification={showNotification} toggleNotification={toggleNotification} profileImage={profileImage} />
          <hr />
          <form className="search-form">
            <div className="form-field">
              <label htmlFor="location">Location</label>
              <input type="text" id="location" name="location" value={searchFormData.location} onChange={handleInputChange} />
            </div>
            <div className="form-field">
              <label htmlFor="industry">Industry</label>
              <input type="text" id="industry" name="industry" value={searchFormData.industry} onChange={handleInputChange} />
            </div>
            <div className="form-field salary">
              <label htmlFor="min-salary">Minimum Salary (in LPA)</label>
              <input type="number" id="min-salary" name="minSalary" value={searchFormData.minSalary} onChange={handleInputChange} />
              <label htmlFor="max-salary">Maximum Salary (in LPA)</label>
              <input type="number" id="max-salary" name="current_salary" value={searchFormData.current_salary} onChange={handleInputChange} />
            </div>
            <div className="form-field">
              <label htmlFor="min-experience">Minimum Experience</label>
              <input type="number" id="min-experience" name="minExperience" value={searchFormData.minExperience} onChange={handleInputChange} />
            </div>
            <div className="form-field">
              <label htmlFor="max-experience">Maximum Experience</label>
              <input type="number" id="max-experience" name="experience" value={searchFormData.experience} onChange={handleInputChange} />
            </div>
            <button type="button" onClick={handleSearch}>Search Candidates</button>
          </form>
          {/* Display the search result message */}
          {searchResultMessage && <p style={{ color: 'red' }}>{searchResultMessage}</p>}
          {/* Display search results */}
          {searchResults.length > 0 && searchResults.map((result, index) => (
            <div key={index} className="profile-card">
              <div className="profile-section">
                <div className="basic-info">
                  <img src={result.profile_pics ? result.profile_pics : profileImage} className="profile-photo" width="150" height="150" alt="Profile Photo" />
                  <h2>{result.full_name}</h2>
                  <p><strong>Gender:</strong> {result.gender}</p>
                  <p><strong>Date of Birth:</strong> {result.date_of_birth}</p>
                  <p><strong>Industry:</strong> {result.industry}</p>
                  <p><strong>Location:</strong> {result.location}</p>
                  <p><strong>Address:</strong> {result.address}</p>
                  <p><strong>Country:</strong> {result.country_code}</p>
                </div>
                <div className="work-info">
                  <h2>Contact Information</h2>
                  <p><strong>Email:</strong> {result.email}</p>
                  <p><strong>Phone Number:</strong> {result.phone_number}</p>
                  <p><strong>Current Location:</strong> {result.current_location}</p>
                  <p><strong>Current Salary:</strong> {result.current_salary}</p>
                  <p><strong>Experience:</strong> {result.experience} years</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default EmployeeSearch;

import Header from '../common/Header'; // Adjusted import path
import Footer from '../common/Footer'; // Adjusted import path
import Login from './Login';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// import '../../styles/styles.css'; // Adjusted import path for styles.css
import '../../styles/main.css';
import { Link } from 'react-router-dom';

function HomePage() {

  const navigate = useNavigate();

  useEffect(() => {
    // Check the user's authentication status
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    
    // If the user is authenticated, redirect them to their respective dashboard
    if (isAuthenticated === 'true') {
      const role_id = localStorage.getItem('role_id');
      if (role_id === '3') {
        navigate('/promoter/dashboard');
      } else if (role_id === '2') {
        navigate('/employer/dashboard');
      }
    }
  }, [navigate]);

  return (
    <div>
      <Header />
        <main id="main-content">
        <Login/>
      </main>
      <Footer /> {/* Adding the Footer component here */}
    </div>
  );
}
export default HomePage;

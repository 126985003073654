import React, { useState, useEffect } from 'react';
import '../../../styles/store.css';
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea';
import DataTable from 'react-data-table-component'; 
import { BASE_URL } from '../../../utils/api'; // Ensure this is correctly set

function TrainingVideos() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch videos from API
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/training/videos`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();

        if (response.ok) {
          setVideos(result.data);
        } else {
          setError(result.message || 'Failed to fetch videos');
        }
      } catch (err) {
        setError('Something went wrong. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  // DataTable columns configuration
  const columns = [
    {
      name: 'Title',
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: false,
    },
    {
      name: 'Video',
      cell: (row) => (
        <video width="250" controls>
          <source src={row.video_url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ),
    },
    {
      name: 'Date',
      selector: (row) => new Date(row.created_at).toLocaleDateString(),
      sortable: true,
    },
  ];

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />

          {loading ? (
            <p>Loading videos...</p>
          ) : error ? (
            <p className="error">{error}</p>
          ) : (
            <DataTable
              title="Training Videos"
              columns={columns}
              data={videos}
              pagination
              highlightOnHover
              pointerOnHover
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TrainingVideos;

import React, { useState } from 'react';
import Header from '../common/Header'; // Adjusted import path
import Footer from '../common/Footer'; // Adjusted import path
import { CiTextAlignCenter, CiTextAlignLeft } from 'react-icons/ci';

function PrivacyPolicy() {
  const [showNotification, setShowNotification] = useState(false);

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      color: '#333',
    },
    mainContent: {
      backgroundColor: '#f9f9f9',
      padding: '30px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    profileText: {
      fontSize: '22px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    aboutContent: {
      lineHeight: '1.6',
      fontSize: '1em',
    },
    paragraph: {
      marginBottom: '20px',
    },
    orderedList: {
      paddingLeft: '20px',
      marginBottom: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    listItemStrong: {
      fontWeight: 'bold',
      color: '#2c3e50',
    },
    hr: {
      border: 0,
      height: '1px',
      background: '#ccc',
      marginBottom: '20px',
    },
  };

  return (
    <div>
      <Header />
      <div style={styles.container}>
        <div style={styles.mainContent} id="main-content">
          <div style={styles.profileText} id="profile-heading">Privacy Policy</div>
          <hr style={styles.hr} />
          <div style={styles.aboutContent}>
            <p style={styles.paragraph}>
              Welcome to Promoter Pal! Your privacy is important to us, and we are committed to protecting your personal information. This privacy policy explains how we collect, use, and safeguard your data when you visit our website and use our services.
            </p>
            <h3 style={styles.profileText}>Information We Collect</h3>
            <p style={styles.paragraph}>
              When you use Promoter Pal, we may collect the following types of information:
            </p>
            <ol style={styles.orderedList}>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Personal Information:</strong> Name, email address, phone number, job preferences, location.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Usage Information:</strong> Information about how you use our website and services.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Technical Information:</strong> IP address, browser type, operating system, and device information.
              </li>
            </ol>
            <h3 style={styles.profileText}>How We Use Your Information</h3>
            <p style={styles.paragraph}>
              We use the information we collect for the following purposes:
            </p>
            <ol style={styles.orderedList}>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Job Search Assistance:</strong> To help you find new job opportunities according to your location and needs.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Sales Enhancement:</strong> To provide you with access to new technologies to improve your sales performance.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Store Location Updates:</strong> To allow you to update your current store location for better visibility.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Training Sessions:</strong> To offer training sessions on sales and other relevant skills.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>PF and ESIC Portal Access:</strong> To connect you with the PF and ESIC portals for easy access to your details.
              </li>
              {/* <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Bill Payments:</strong> To enable you to pay bills directly through our platform.
              </li> */}
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Medical Assistance:</strong> To help you find nearby hospitals for quick medical attention.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Emergency Services:</strong> To provide a direct link to emergency services for swift responses in critical situations.
              </li>
              {/* <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Financial Services:</strong> To connect you with relevant apps for loans and credit cards.
              </li> */}
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>News Feed:</strong> To keep you updated with the latest news and blog posts in your industry.
              </li>
            </ol>
            <h3 style={styles.profileText}>Data Sharing and Disclosure</h3>
            <p style={styles.paragraph}>
              We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except in the following circumstances:
            </p>
            <ul style={styles.orderedList}>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our website and providing our services.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Legal Requirements:</strong> We may disclose your information if required by law or in response to a valid request by a law enforcement agency.
              </li>
            </ul>
            <h3 style={styles.profileText}>Data Security</h3>
            <p style={styles.paragraph}>
              We implement a variety of security measures to maintain the safety of your personal information. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.
            </p>
            <h3 style={styles.profileText}>Cookies</h3>
            <p style={styles.paragraph}>
              Promoter Pal uses cookies to enhance your experience on our website. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your web browser (if you allow) that enable the site’s or service provider’s systems to recognize your browser and capture and remember certain information.
            </p>
            <h3 style={styles.profileText}>Your Rights</h3>
            <p style={styles.paragraph}>
              You have the following rights regarding your personal information:
            </p>
            <ul style={styles.orderedList}>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Access:</strong> You can request a copy of the personal information we hold about you.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Correction:</strong> You can request that we correct any inaccuracies in your personal information.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Deletion:</strong> You can request that we delete your personal information.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Objection:</strong> You can object to the processing of your personal information.
              </li>
              <li style={styles.listItem}>
                <strong style={styles.listItemStrong}>Restriction:</strong> You can request that we restrict the processing of your personal information.
              </li>
            </ul>
            <h3 style={styles.profileText}>Changes to This Privacy Policy</h3>
            <p style={styles.paragraph}>
              We may update this privacy policy from time to time. Any changes will be posted on this page with an updated revision date.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;

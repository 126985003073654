import React, { useState, useEffect } from 'react';
// import '../../styles/jobseeker-signup.css'; // Adjusted import path for styles.css
import '../../styles/main.css';
import { Link, useNavigate , useLocation } from 'react-router-dom';
import { BASE_URL } from '../../utils/api'; // Adjust the path as needed
import broImage from '../../assets/img/bro.png';
import empImage from '../../assets/img/emp-pic-login.png';
import logogf from '../../assets/img/@-logo.gif';
import shape from '../../assets/img/shapes.gif';
import logingif from '../../assets/img/arrow.gif';
import Header from '../common/Header'; // Adjusted import path
import Footer from '../common/Footer'; // Adjusted import path


function JobSeekerSignup() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    password_confirmation: '',
    role_id: 3
  });

  const [errorMessages, setErrorMessages] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { pathname } = useLocation();


  useEffect(() => {
    document.title = "Promoterpal-signup";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/api/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      if (!response.ok) {
        if (responseData.errors) {
          const errors = Object.values(responseData.errors).flat();
          setErrorMessages(errors);
        } else {
          setErrorMessages(['Signup failed. Please try again.']);
        }
      } else {
        localStorage.setItem('userEmail', formData.email);
        navigate(`/promoter/registration?email=${formData.email}`);

      }
    } catch (error) {
      console.error('Signup failed:', error);
      setErrorMessages(['Signup failed. Please try again.']);
    }
  };

  const location = useLocation();
  const path = location.pathname;

  const renderSignupLink = () => {
    if (path === '/') {
      return <p>Don't have an account? Please <Link to="/promoter/signup">Sign Up</Link></p>;
    } else if (path === '/employer/login') {
      return <p>Don't have an account? Please <Link to="/employer/signup">Sign Up</Link></p>;
    }
    return null;
  };

  return (
    <div>
      <Header />
      <div className="container">
        <div className="left-login-img">
          <div className="Photo">
            <img id="login-image" src={empImage} alt="image not found" />;
          </div>
        </div>
        <div className="right-login-form">
        <h2 className="login-text">Promoter Signup</h2> 
        <form onSubmit={handleSubmit} class="signup-form">
            <div className="input-box">
              <img src={logogf} alt="" />
               <input type="email" id='email' placeholder="Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
            </div>
            <div className="input-box">
              <img src={shape} alt="" />
              <input type="password" id="password" placeholder="Password" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} required />
            </div>
            <div className="input-box">
              <img src={shape} alt="" />
              <input type="password" id="confirm-password" placeholder="Confirm Password" value={formData.password_confirmation} onChange={(e) => setFormData({ ...formData, password_confirmation: e.target.value })} required />
            </div>
            <Link to="/forgot-password" className="forgot-password">Forgot password ?</Link>
            <button type="submit" className="btn-btn">Signup<img id="arrow" src={logingif} alt="" /></button>
          </form>
          {errorMessages.length > 0 && (
            <div className="error-messages">
              <h3>Error:</h3>
              <ul>
                {errorMessages.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="signup-link">
             {renderSignupLink()}
          </div>
          <hr className="dividerx" />
          <div className="others-option">Or Log In Using</div>
          <div className="social-login">
            <button type="button" className="social-button google">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google" viewBox="0 0 16 16">
                <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
              </svg>
            </button>
            <button type="button" className="social-button linkedin">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
              </svg>
            </button>
            <button type="button" className="social-button facebook">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <Footer /> {/* Adding the Footer component here */}
    </div>
  );
}

export default JobSeekerSignup;

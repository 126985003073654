import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import ProfileArea from '../common/ProfileArea'; // Import ProfileArea component
import '../../styles/header.css';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [roleId, setRoleId] = useState(null); // Add state for role ID
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Promoterpal";
  }, []);

  useEffect(() => {
    // Check if the user is authenticated by checking for a token
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('userName');
    const role = localStorage.getItem('role_id'); // Fetch role ID from localStorage
    if (token && user) {
      setIsAuthenticated(true);
      setUsername(user);
      setRoleId(role); // Set role ID
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogoClick = () => {
    if (!isAuthenticated) {
      navigate('/');
    } else {
      if (roleId == 3) {
        navigate('/promoter/dashboard');
      } else if (roleId == 2) {
        navigate('/employer/dashboard');
      }
    }
  };

  return (
    <header>
      <nav className="navbar">
        <div className="logo" onClick={handleLogoClick}>
          <img src={logo} alt="Promoterpal Logo" />
        </div>
        <div>
        <span className="home-button" onClick={handleLogoClick}>Home</span>
          {isAuthenticated ? (
            <div className="welcome-text">
              WELCOME, {username.toUpperCase()}
            </div>
          ) : (
            <div className="dropdown" id="dropdownContainer">
              <div className="dropdown-option" onClick={toggleDropdown}>
                <div className="dropdown-text">For Company Login</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-chevron-${dropdownOpen ? 'up' : 'down'} dropdown-icon`} viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                </svg>
              </div>
              {dropdownOpen && (
                <div className="dropdown-content" id="dropdownContent">
                  <Link to="/employer/login">Company Login</Link>
                </div>
              )}
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;

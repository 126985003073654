import React, { useState } from 'react';
import '../../../styles/store.css';
import { BiSearch, BiX } from 'react-icons/bi'; // Import the BiSearch and BiX icons from react-icons/bi
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea'; // Import ProfileArea component
import { BASE_URL } from '../../../utils/api';


function CurrentStore() {
  const [currentLocation, setCurrentLocation] = useState('SJCE STEP Office, JC college, Mysore, Karnataka');
  const [message, setMessage] = useState('');

  const handleChange = (event) => {
    setCurrentLocation(event.target.value);
  };

  const handleSubmit = () => {
    // Get user_id and token from localStorage
    const user_id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    // Prepare POST request data
    const postData = {
      user_id: user_id,
      current_location: currentLocation,
    };

    // Send POST request to update current location
    fetch(`${BASE_URL}/api/update/current/location`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Location updated successfully:', data);
      setMessage('Location updated successfully!');
    })
    .catch(error => {
      console.error('Error updating location:', error);
      setMessage('Failed to update location');
    });
  };

  const handleLocateMe = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        // Fetch address using reverse geocoding with OpenStreetMap Nominatim API
        fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`)
          .then(response => response.json())
          .then(data => {
            if (data && data.display_name) {
              setCurrentLocation(data.display_name);
            } else {
              setCurrentLocation('Location not found');
            }
          })
          .catch(error => {
            console.error('Error fetching location:', error);
            setCurrentLocation('Location not found');
          });
      }, (error) => {
        console.error('Error getting current position:', error);
        setCurrentLocation('Location not found');
      });
    } else {
      setCurrentLocation('Geolocation is not supported by this browser.');
    }
  };
  
  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          <div className="container-1">
            <label htmlFor="currentLocation">Current Location</label>
            <div id="message" style={{ color: 'red', marginTop:'8px' }}>{message}</div>
            <div className="location-input">
              <input
                type="text"
                className='currentLocation'
                id="currentLocation"
                value={currentLocation}
                onChange={handleChange}
              />
              <button className="locate-me" onClick={handleLocateMe}>Locate Me</button>
            </div>
            <button className="button-store" onClick={handleSubmit}>Update</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurrentStore;

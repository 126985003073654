import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../utils/api'; // Adjust the path as needed

function LogoutButton() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // You may need to include authentication headers if required by your API
        },
        // Include any payload required by your logout API, such as user tokens
      });

      if (response.ok) {
        // Clear authentication-related data from local storage
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('role_id');
        localStorage.removeItem('userName');
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        
        // Redirect the user to the home page
        navigate('/');
      } else {
        // Handle logout error, if any
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <button onClick={handleLogout} className='logout-btn'>Logout</button>
  );
}

export default LogoutButton;

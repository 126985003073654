import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../../styles/forgotpassword.css";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { BASE_URL } from "../../utils/api";

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const location = useLocation();

  useEffect(() => {
    document.title = "Promoterpal - Change Password";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const email = params.get("email");

    if (!token || !email) {
      setError(["Invalid or missing token/email."]);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/api/reset-password`, {
        token,
        email,
        password,
        password_confirmation: passwordConfirmation,
      });
      setMessage(response.data.message);
      setError([]);
      setIsSuccess(true);
      setPassword("");
      setPasswordConfirmation("");
    } catch (error) {
      setMessage("");
      const errorMessages = error.response.data.errors || [
        error.response.data.message || "An error occurred",
      ];
      setError(errorMessages);
      setIsSuccess(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="container-center">
        <div className="row w-100">
          <div className="col-12 col-md-6 offset-md-3 text-center">
            <h3 className="fgt">Change Password</h3>
            <div className="input-box-reg">
              {message && (
                <p className={`message mt-3 ${isSuccess ? 'text-success' : ''}`}>
                  {message}
                </p>
              )}
              {error.length > 0 && (
                <ul className="error mt-3 text-danger">
                  {error.map((err, index) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              )}
              <form onSubmit={handleSubmit}>
                <input
                  type="password"
                  id="password"
                  className="forrgt-pass"
                  placeholder="Enter new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="password"
                  id="passwordConfirmation"
                  className="forrgt-pass"
                  placeholder="Confirm new password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
                <button className="submit-btn-1" type="submit">
                  Submit
                </button>
              </form>
              {isSuccess && (
                <p className="success-message mt-3" style={{ color: "green" }}>
                  Password reset successfully!
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ChangePassword;

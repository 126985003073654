import React, { useState } from 'react';
import TabContainer from '../employerDashboard/TabContainer'; // Adjust the path as needed
import '../../../styles/jobsearch.css';
import EmployerSidebar from '../../common/EmployerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea';

function OurTrainingBlog() {
  const [showNotification, setShowNotification] = useState(false);

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  return (
    <div>
      <Header />
      <div className="container">
        <EmployerSidebar />
        <div className="main-content" id="main-content-1">
          <ProfileArea showNotification={showNotification} toggleNotification={toggleNotification} profileImage={profileImage} />
          <hr />
          <TabContainer />
        </div>
      </div>
    </div>
  );
}

export default OurTrainingBlog;

import React from 'react';
import '../../../styles/store.css';
import { BiSearch, BiX } from 'react-icons/bi'; // Import the BiSearch and BiX icons from react-icons/bi
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea'; // Import ProfileArea component

function PfAndEsic() {
 
  const handleConnectPF = () => {
    window.open('https://www.epfindia.gov.in/site_en/For_Employees.php', '_blank');
  };

  const handleConnectESIC = () => {
    window.open('https://www.esic.nic.in/', '_blank');
  };

  const handleKnowPFDetails = () => {
    window.open('https://www.epfindia.gov.in/site_en/For_Employees.php', '_blank');
  };

  const handleKnowESICDetails = () => {
    window.open('https://www.esic.nic.in/', '_blank');
  };

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          <div>
            <div className="button-pf" onClick={handleConnectPF}>Connect PF Department <img src="../../icon/box-arrow.png" alt="icon" /></div>
            <div className="button-pf" onClick={handleConnectESIC}>Connect ESIC Portal <img src="../../icon/box-arrow.png" alt="icon" /></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PfAndEsic;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { WiDaySunny, WiCloudy, WiRain } from 'react-icons/wi';
import '../../styles/dashboard.css';
import EmployerSidebar from '../common/EmployerSidebar';
import Header from '../common/Header';
import ProfileArea from '../common/ProfileArea';
import profileImage from '../../assets/images/profile.jpg';

function JobSeekerDashboard() {
  const [weather, setWeather] = useState(null);
  const [news, setNews] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Simulated stock market data generator
  const generateStockData = () => {
    const baseValue = 17000; // Base value for NIFTY 50
    const volatility = 0.02; // 2% volatility
    const numDays = 30;
    
    let currentValue = baseValue;
    const data = [];
    
    for (let i = numDays - 1; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      
      // Simulate daily change
      const change = (Math.random() - 0.5) * 2 * volatility;
      currentValue = currentValue * (1 + change);
      
      data.push({
        date: date.toISOString().split('T')[0],
        close: parseFloat(currentValue.toFixed(2))
      });
    }
    
    return data;
  };

  // Simulated tech news generator
  const generateTechNews = () => {
    const headlines = [
      "New AI Model Breaks Performance Records",
      "Tech Giant Unveils Revolutionary Smartphone",
      "Startup Raises $100M for Quantum Computing Research",
      "Major Cybersecurity Breach Affects Millions",
      "Electric Vehicle Sales Surpass Expectations",
      "VR Technology Transforms Remote Work",
      "Breakthrough in Renewable Energy Storage",
      "Tech Firms Commit to Carbon Neutrality by 2030",
      "New Coding Language Gains Popularity Among Developers",
      "Robotics Innovation Streamlines Manufacturing Processes"
    ];

    return headlines.slice(0, 3).map(title => ({
      title,
      publishedAt: new Date(Date.now() - Math.floor(Math.random() * 7 * 24 * 60 * 60 * 1000)).toISOString()
    }));
  };

  useEffect(() => {
    // Fetch weather data
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=2a2cfd2b182f290c8aa1bf423fb98723&units=metric`)
        .then(response => setWeather(response.data))
        .catch(error => console.error('Error fetching weather:', error));
    });

    // Generate simulated news data
    setNews(generateTechNews());

    // Simulated application data
    setApplicationData([
      { status: 'Applied', count: Math.floor(Math.random() * 20) },
      { status: 'Interview', count: Math.floor(Math.random() * 10) },
      { status: 'Offer', count: Math.floor(Math.random() * 5) },
      { status: 'Rejected', count: Math.floor(Math.random() * 15) },
    ]);

    // Generate simulated stock data
    setStockData(generateStockData());
  }, []);

  const getWeatherIcon = (weatherCode) => {
    if (weatherCode >= 200 && weatherCode < 600) return <WiRain size={50} />;
    if (weatherCode >= 600 && weatherCode < 700) return <WiCloudy size={50} />;
    return <WiDaySunny size={50} />;
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box>
      <Header />
      <Box className="container">
        <EmployerSidebar isOpen={isSidebarOpen} />
        <Box className={`main-content ${isSidebarOpen ? 'sidebar-open' : ''}`} id="main-content">
          <ProfileArea profileImage={profileImage} toggleSidebar={toggleSidebar}  />
          <hr />
          <Typography variant="h4" gutterBottom>Welcome to Dashboard</Typography>
          
          <Grid container spacing={3}>
            {/* Application Status Chart */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Application Status</Typography>
                  <Box height={300}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={applicationData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="status" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="count" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Simulated Indian Stock Market Chart */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Simulated NIFTY 50 Index - Last 30 Days</Typography>
                  <Box height={300}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={stockData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tick={false} />
                        <YAxis domain={['auto', 'auto']} />
                        <Tooltip />
                        <Line type="monotone" dataKey="close" stroke="#82ca9d" />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Weather Widget */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Current Weather</Typography>
                  {weather && (
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box>
                        <Typography variant="h3">{Math.round(weather.main.temp)}°C</Typography>
                        <Typography variant="subtitle1">{weather.name}</Typography>
                      </Box>
                      {getWeatherIcon(weather.weather[0].id)}
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>

            {/* Latest Tech News Widget */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Latest Tech News</Typography>
                  {news.map((article, index) => (
                    <Box key={index} mb={2}>
                      <Typography variant="subtitle1">{article.title}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {new Date(article.publishedAt).toLocaleDateString()}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default JobSeekerDashboard;
import React, { useEffect, useState } from 'react';
import '../../styles/dashboard.css'; // Adjusted import path for styles.css
import EmployerSidebar from '../common/EmployerSidebar'; // Adjusted import path
import profileImage from '../../assets/images/profile.jpg'; // Import the profile image
import Header from '../common/Header'; // Adjusted import path
import ProfileArea from '../common/ProfileArea'; // Import ProfileArea component
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { BASE_URL } from '../../utils/api';
import axios from 'axios';


function EmployerEditProfile() {
  const [showNotification, setShowNotification] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // Use useNavigate for navigation

  useEffect(() => {
    // Check if user is authenticated
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated');
    if (storedIsAuthenticated === 'true') {
      setIsAuthenticated(true);
    } else {
      // If not authenticated, navigate to login page
      navigate('/employer/login');
    }
  }, [navigate]); // Add navigate to dependency array

  useEffect(() => {
    // Fetch profile details from API
    const fetchProfileDetails = async () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('id');
      try {
        const response = await fetch(`${BASE_URL}/api/employer-profile/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setProfileData(data.data);
          setFormData(data.data);
        } else {
          // Handle errors if needed
          console.error('Failed to fetch profile details');
        }
      } catch (error) {
        console.error('Error fetching profile details:', error);
      }
    };

    if (isAuthenticated) {
      fetchProfileDetails();
    }
  }, [isAuthenticated]);

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  const handleEditButtonClick = () => {
    setIsEditMode(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const toggleSidebar = () => {
    const sidebar = document.querySelector('.sidebar');
    const profileForm = document.querySelector('.profile-form');
    
    // Toggle sidebar visibility
    sidebar.classList.toggle('open');
    // Toggle profile form visibility
    profileForm.classList.toggle('hide');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsEditMode(false);

    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('id');

      const response = await fetch(`${BASE_URL}/api/update/employer-profile/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setSuccessMessage('Profile updated successfully');
        // Optionally, reset form fields or perform any other actions
      } else {
        // Handle other response statuses
        const data = await response.json();
        setErrorMessage(data.error || 'Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setErrorMessage('Failed to update profile');
    }
  };

  
  return (
    <div>
      <Header />
      <div className="container">
        <EmployerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea showNotification={showNotification} toggleNotification={toggleNotification} profileImage={profileImage} toggleSidebar={toggleSidebar} />
          <hr />
          <button className="edit-button" onClick={handleEditButtonClick} disabled={isEditMode}>
            <img src="../../assets/img/edit.png" alt="" />
            Edit
          </button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {successMessage && <div className="success-message" style={{ color: 'green' }}>{successMessage}</div>}
          <form className="profile-form" onSubmit={handleSubmit}>
            {Object.entries(formData).map(([key, value]) => {
              if (key !== 'role_id' && key !== 'user_id' && key !== 'logo') {
                return (
                  <div className="form-group" key={key}>
                    <label>{key.replace(/_/g, ' ').toUpperCase()} :</label>
                    <input
                      type="text"
                      name={key}
                      placeholder={key.replace(/_/g, ' ')}
                      value={value}
                      onChange={handleInputChange}
                      disabled={!isEditMode || key === 'email'}
                    />
                  </div>
                );
              }
              return null;
            })}
            <button className="change-password" type="submit" disabled={!isEditMode}>
              <img src="../../assets/img/autorenew-logo.gif" alt="" />
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EmployerEditProfile;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/forgotpassword.css";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { BASE_URL } from "../../utils/api"; // Adjust the path as needed

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State for loader
  const [showSuccess, setShowSuccess] = useState(false); // State for success message

  useEffect(() => {
    document.title = "Promoterpal - Forgot Password";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader

    try {
      const response = await axios.post(`${BASE_URL}/api/forgot-password`, { email });
      setMessage(response.data.message);
      setError("");
      setShowSuccess(true); // Display success message
    } catch (error) {
      setMessage("");
      setError(error.response.data.message || "An error occurred");
    }

    setIsLoading(false); // Hide loader
  };

  return (
    <div>
      <Header />
      <div className="container-center">
        <div className="row w-100">
          <div className="col-12 col-md-6 offset-md-3 text-center">
            <h4 className="fgt">Forgot Password?</h4>
            {/* <p className="email-text">Please Provide your Email Address</p> */}
            <div className="input-box-reg">
              {showSuccess && (
                <p className="success mt-3" style={{ color: "green" }}>
                  Password reset email sent successfully. Please check your inbox.
                </p>
              )}
              {message && !showSuccess && (
                <p className="message mt-3">{message}</p>
              )}
              {error && <p className="error mt-3" style={{ color: "red" }}>{error}</p>}
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  id="email"
                  className="forrgt-pass"
                  placeholder="Please Provide your Email Address"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="submit-btn-1 w-100" type="submit">
                  Submit
                </button>
              </form>
              {isLoading && <div className="loader"></div>} {/* Loader */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ForgotPassword;

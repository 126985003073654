import React from "react";
import { NavLink } from "react-router-dom";

function JobSeekerSidebar({ isOpen, toggleProfileForm, toggleSidebar }) {
  const handleLinkClick = () => {
    // Toggle profile form visibility when sidebar link is clicked
    toggleProfileForm();
    // Toggle sidebar visibility when sidebar link is clicked
    toggleSidebar();
  };
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <ul>
        <li>
          <NavLink
            to="/promoter/dashboard/jobforyou"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Jobs For You
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/store"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Update Current Store
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/jobsearch"
            className={({ isActive }) => (isActive ? "active asd" : "asd")}
          >
            Job Search
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/training-and-technology"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Training & Technology
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/promoter-training-videos"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Our Training Blog
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            to="/promoter/dashboard/news-community-posting"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            News/Community Posting
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to="/promoter/dashboard/social-media-apps"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Social Media Apps
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/pf-and-esic"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            PF & ESIC
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/emerency-service"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Emergency Service
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/hospital-locator"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Hospital Locator
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/travel-apps"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Travel Apps
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/loans-apps"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Home & Personal Loan
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/credit-card-apps"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Credit Card Apps
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/booking-apps"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Booking Apps
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/health-insurance"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Health Insurance
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/promoter/dashboard/booking-and-finance"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Payment Apps
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default JobSeekerSidebar;

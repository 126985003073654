import React, { useState, useEffect, useRef } from 'react';
import '../../../styles/store.css';
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea';
import { BASE_URL } from '../../../utils/api';

function TrainingAndTechnology() {
  const [activeTab, setActiveTab] = useState("sales");
  const [videos, setVideos] = useState([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const videoRefs = useRef({});

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const fetchVideos = async () => {
    const userId = localStorage.getItem('id');
    const token = localStorage.getItem('token');
    const response = await fetch(`${BASE_URL}/api/get/sales-technical/skills/video?user_id=${userId}&type=${activeTab}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setVideos(data);
  };

  useEffect(() => {
    fetchVideos();
  }, [activeTab]);

  const handlePlay = (index) => {
    Object.keys(videoRefs.current).forEach((key) => {
      if (key !== index.toString() && videoRefs.current[key]) {
        videoRefs.current[key].pause();
      }
    });
    setCurrentPlaying(index);
  };

  const checkVideoUrl = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (error) {
      console.error('Error checking video URL:', error);
      return false;
    }
  };

  const uploadVideo = async (title, video, type) => {
    setIsLoading(true);
    setUploadError(null);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('video', document.querySelector('.upload-input').files[0]);
    formData.append('type', type);

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('id');
    formData.append('user_id', userId);

    try {
      const response = await fetch(`${BASE_URL}/api/upload/sales-technical/skills/video`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to upload video');
      }

      const result = await response.json();
      const isVideoAccessible = await checkVideoUrl(result.video_url);

      if (!isVideoAccessible) {
        throw new Error('Uploaded video is not accessible');
      }

      setUploadSuccess(true);
      await fetchVideos(); // Refresh the video list
    } catch (error) {
      console.error('Error uploading video:', error.message);
      setUploadError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          <div className="custom-tabs">
            <div
              className={`custom-tab ${activeTab === "sales" ? "active" : ""}`}
              onClick={() => handleTabChange("sales")}
            >
              Sales Skills
            </div>
            <div
              className={`custom-tab ${activeTab === "technical" ? "active" : ""}`}
              onClick={() => handleTabChange("technical")}
            >
              Technical Skills
            </div>
          </div>
          <div className="custom-tab-content">
            {activeTab === "sales" && (
              <>
                <div className="videos">
                  {videos.map((video, index) => (
                    <div className="video" key={index}>
                      {video.is_approved === 1 ? (
                        <video
                        ref={(el) => (videoRefs.current[index] = el)}
                        controls
                        onPlay={() => handlePlay(index)}
                        src={video.video_url}
                      ></video>
                      ) : (
                        <div className="video-not-approved">
                          <div className="video-placeholder"></div>
                          <p className="not-approved-message">Not Approved.</p>
                          <div className="video-type-icon"></div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="upload-section">
                  <h3>Upload Your Sales Skills Video</h3>
                  <input type="file" className="upload-input" />
                  <button className="upload-button" onClick={() => uploadVideo('Sales Skills Videos', 'video', 'sales')} disabled={isLoading}>
                    {isLoading ? 'Uploading...' : 'Upload'}
                  </button>
                  {isLoading && <div className="loader"></div>}
                  {uploadSuccess && <p className="success-message">Video uploaded successfully!</p>}
                  {uploadError && <p className="error-message">{uploadError}</p>}
                </div>
              </>
            )}
            {activeTab === "technical" && (
              <>
                <div className="videos">
                  {videos.map((video, index) => (
                    <div className="video" key={index}>
                      {video.is_approved === 1 ? (
                        <video
                          ref={(el) => (videoRefs.current[index] = el)}
                          width="315"
                          height="250"
                          controls
                          onPlay={() => handlePlay(index)}
                          src={video.video_url}
                        ></video>
                      ) : (
                        <div className="video-not-approved">
                          <div className="video-placeholder"></div>
                          <p className="not-approved-message">Not Approved.</p>
                          <div className="video-type-icon"></div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="upload-section">
                  <h3>Upload Your Technical Skills Video</h3>
                  <input type="file" className="upload-input" />
                  <button className="upload-button" onClick={() => uploadVideo('Technical Skills Videos', 'video', 'technical')} disabled={isLoading}>
                    {isLoading ? 'Uploading...' : 'Upload'}
                  </button>
                  {isLoading && <div className="loader"></div>}
                  {uploadSuccess && <p className="success-message">Video uploaded successfully!</p>}
                  {uploadError && <p className="error-message">{uploadError}</p>}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .loader {
          border: 5px solid #f3f3f3;
          border-top: 5px solid #3498db;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          animation: spin 1s linear infinite;
          margin: 10px auto;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

        .error-message {
          color: red;
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
}

export default TrainingAndTechnology;
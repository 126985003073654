import React from "react";
import logo from "../../assets/img/logo.png";
import googleIcon from "../../assets/img/google-icon.png";
import facebookIcon from "../../assets/img/facebook-icon.png";
import instagramIcon from "../../assets/img/instagram-icon.png";
import twitterIcon from "../../assets/img/twitter-icon.png";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="footer-content">
        <div className="left-footer">
          <div className="footer-logo">
            <img src={logo} alt="Promoterpal Logo" />
            <span>
              Unlocking Your Career Potential - Your Partner
              <br /> in Professional Success!
            </span>
          </div>
        </div>
        <div className="right-footer">
          <div className="footer-links">
            <Link to="/aboutus">About Us</Link>
            <Link to="/contactus">Contact Us</Link>
          </div>
          <div className="footer-social">
            {/* <a href="#"><img src={googleIcon} alt="Google" /></a> */}
            <a href="https://www.facebook.com/share/vyN6o56zwUC6vuEz/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/promoterpal?igsh=MWU4a3pjaWt2d2tveA==" target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} alt="Instagram" />
            </a>
            <a href="https://x.com/promoterpal" target="_blank" rel="noopener noreferrer">
              <img src={twitterIcon} alt="Twitter" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <Link
          to="/privacy-policy"
          style={{ color: "#ffff", textDecoration: "none" }}
        >
          Privacy Policy
        </Link>{" "}
        | &copy; <span id="year">{currentYear}</span> Promoterpal
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import Aboutus from './components/pages/Aboutus';
import Contactus from './components/pages/Contactus';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import JobSeekerSignup from './components/pages/JobSeekerSignup';
import EmployerSignup from './components/pages/EmployerSignup';
import JobSeekerDashboard from './components/pages/JobSeekerDashboard';
import Login from './components/pages/Login';
import ForgotPassword from './components/pages/ForgotPassword';
import ChangePassword from './components/pages/ChangePassword';
import JobSeekerRegistration from './components/pages/JobSeekerRegistration'; 
import PromoterRegistrationSuccess from './components/pages/PromoterRegistrationSuccess';
import EmployerRegistration from './components/pages/EmployerRegistration'; 
import CompanyRegistrationSuccess from './components/pages/CompanyRegistrationSuccess'; 
import JobSearch from './components/pages/jobseekerDashboard/JobSearch';
import TrainingVideos from './components/pages/jobseekerDashboard/TrainingVideos';
import PromoterEditProfile from './components/pages/PromoterEditProfile';
import EmployerEditProfile from './components/pages/EmployerEditProfile';
import JobForYou from './components/pages/jobseekerDashboard/JobForYou';
import CurrentStore from './components/pages/jobseekerDashboard/CurrentStore';
import TrainingAndTechnology from './components/pages/jobseekerDashboard/TrainingAndTechnology';
import PfAndEsic from './components/pages/jobseekerDashboard/PfAndEsic';
import BookingAndFinance from './components/pages/jobseekerDashboard/BookingAndFinance';
import TravelApps from './components/pages/jobseekerDashboard/TravelApps';
import LoanApp from './components/pages/jobseekerDashboard/LoanApp';
import HelthInsurance from './components/pages/jobseekerDashboard/HelthInsurance';
import BookingApps from './components/pages/jobseekerDashboard/BookingApps';
import CreditCardApps from './components/pages/jobseekerDashboard/CreditCardApps';
import SocialMedia from './components/pages/jobseekerDashboard/SocialMedia';
import HospitalLocator from './components/pages/jobseekerDashboard/HospitalLocator';
import EmergancyService from './components/pages/jobseekerDashboard/EmergancyService';
import NewsCommunityPosting from './components/pages/jobseekerDashboard/NewsCommunityPosting';
import EmployerDashboard from './components/pages/EmployerDashboard';
import EmployeeSearch from './components/pages/employerDashboard/EmployeeSearch';
import EmployeeSearchResult from './components/pages/employerDashboard/EmployeeSearchResult';
import LabourLaw from './components/pages/employerDashboard/LabourLaw';
import MinWages from './components/pages/employerDashboard/MinWages';
import OurTrainingBlog from './components/pages/employerDashboard/OurTrainingBlog';
import StoreLocator from './components/pages/employerDashboard/StoreLocator';
import SalesIncentivePayout from './components/pages/employerDashboard/SalesIncentivePayout';
import JobPost from './components/pages/employerDashboard/JobPost';

function App() {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const role_id = parseInt(localStorage.getItem('role_id'));

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        
        {/* Routes for unauthenticated users */}
        {!isAuthenticated ? (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/employer/login" element={<HomePage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/promoter/signup" element={<JobSeekerSignup />} />
            <Route path="/employer/signup" element={<EmployerSignup />} />
            <Route path="/promoter/registration" element={<JobSeekerRegistration />} />
            <Route path="/promoter/registerSuccess" element={<PromoterRegistrationSuccess />} />
            <Route path="/employer/registration" element={<EmployerRegistration />} />
            <Route path="/employer/registerSuccess" element={<CompanyRegistrationSuccess />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contactus" element={<Contactus />} />
          </>
        ) : (
          <>
            {/* Routes for authenticated users */}
            {role_id === 3 ? (
              <>
                <Route path="/promoter/dashboard" element={<JobSeekerDashboard />} />
                <Route path="/promoter/dashboard/editprofile" element={<PromoterEditProfile />} />
                <Route path="/promoter/dashboard/jobsearch" element={<JobSearch />} />
                <Route path="/promoter/dashboard/jobforyou" element={<JobForYou />} />
                <Route path="/promoter/dashboard/store" element={<CurrentStore />} />
                <Route path="/promoter/dashboard/training-and-technology" element={<TrainingAndTechnology />} />
                <Route path="/promoter/dashboard/pf-and-esic" element={<PfAndEsic />} />
                <Route path="/promoter/dashboard/booking-and-finance" element={<BookingAndFinance />} />
                <Route path="/promoter/dashboard/travel-apps" element={<TravelApps />} />
                <Route path="/promoter/dashboard/loans-apps" element={<LoanApp />} />
                <Route path="/promoter/dashboard/health-insurance" element={<HelthInsurance />} />
                <Route path="/promoter/dashboard/booking-apps" element={<BookingApps />} />
                <Route path="/promoter/dashboard/credit-card-apps" element={<CreditCardApps />} />
                <Route path="/promoter/dashboard/social-media-apps" element={<SocialMedia />} />
                <Route path="/promoter/dashboard/hospital-locator" element={<HospitalLocator />} />
                <Route path="/promoter/dashboard/emerency-service" element={<EmergancyService />} />
                <Route path="/promoter/dashboard/news-community-posting" element={<NewsCommunityPosting />} />
                <Route path="/promoter/dashboard/promoter-training-videos" element={<TrainingVideos />} />
              </>
            ) : role_id === 2 ? (
              <>
                <Route path="/employer/dashboard" element={<EmployerDashboard />} />
                <Route path="/employer/dashboard/editprofile" element={<EmployerEditProfile />} />
                <Route path="/employer/dashboard/employee-search" element={<EmployeeSearch />} />
                <Route path="/employer/dashboard/employee-search-result" element={<EmployeeSearchResult />} />
                <Route path="/employer/dashboard/labour-law" element={<LabourLaw />} />
                <Route path="/employer/dashboard/min-wages" element={<MinWages />} />
                <Route path="/employer/dashboard/training-blog" element={<OurTrainingBlog />} />
                <Route path="/employer/dashboard/store-locator" element={<StoreLocator />} />
                <Route path="/employer/dashboard/sales-incentice-payout" element={<SalesIncentivePayout />} />
                <Route path="/employer/dashboard/job-post" element={<JobPost />} />
              </>
            ) : null}

            {/* Routes for both authenticated and unauthenticated users */}
            <Route path="/*" element={<Navigate to={role_id === 3 ? "/promoter/dashboard" : "/employer/dashboard"} />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;

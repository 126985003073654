import React from 'react';
import '../../../styles/store.css';
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea'; // Import ProfileArea component

function CreditCardApps() {
  // const linkStyle = {
  //   textDecoration: 'none',
  //   color: 'inherit', // Inherit color from parent to ensure it matches with the <p> tag
  // };

  // const pStyle = {
  //   color: 'black',
  //   textDecoration: 'none',
  // };

  // const imgStyle = {
  //   width: '100px', // Set the width of the logo
  //   height: '100px', // Set the height of the logo
  //   objectFit: 'contain', // Ensure the logo maintains its aspect ratio
  //   marginBottom: '10px',
  // };

  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    color: 'white',
    fontSize: '16px',
    margin: '10px',
    padding: '10px'
  };
  
  const pStyle = {
    margin: '10px 0 0 0',
    fontWeight: 'bold'
  };

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          <div className="credit-card-container">
          <h3>Credit Card Apps for 20,000 Salary</h3>
          <div className="payment-container">
              <a href="https://www.hdfcbank.com/personal/pay/cards/credit-cards" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#0033A0' }}>
                {/* <img src="../../icon/hdfc-logo.png" alt="HDFC Bank" style={imgStyle} /> */}
                <p style={pStyle}>HDFC Bank</p>
              </a>
              <a href="https://www.icicibank.com/personal-banking/cards/credit-card" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#F58220' }}>
                {/* <img src="../../icon/icici-logo.jpg" alt="ICICI Bank" style={imgStyle} /> */}
                <p style={pStyle}>ICICI Bank</p>
              </a>
              <a href="https://www.sbicard.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#0059A9' }}>
                {/* <img src="../../icon/sbi-logo.png" alt="SBI Card" style={imgStyle} /> */}
                <p style={pStyle}>SBI Card</p>
              </a>
              <a href="https://www.axisbank.com/retail/cards/credit-card" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#E53935' }}>
                {/* <img src="../../icon/axis-logo.png" alt="Axis Bank" style={imgStyle} /> */}
                <p style={pStyle}>Axis Bank</p>
              </a>
              <a href="https://www.kotak.com/en/personal-banking/cards/credit-cards.html" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#FF3633' }}>
                {/* <img src="../../icon/kotak-logo.png" alt="Kotak Mahindra Bank" style={imgStyle} /> */}
                <p style={pStyle}>Kotak Mahindra Bank</p>
              </a>
              <a href="https://www.rblbank.com/personal-banking/cards/credit-cards" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#DC291E' }}>
                {/* <img src="../../icon/rbl-logo.png" alt="RBL Bank" style={imgStyle} /> */}
                <p style={pStyle}>RBL Bank</p>
              </a>
              <a href="https://www.yesbank.in/personal-banking/yes-individual/cards/credit-cards" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#0056A4' }}>
                {/* <img src="../../icon/yesbank-logo.png" alt="Yes Bank" style={imgStyle} /> */}
                <p style={pStyle}>Yes Bank</p>
              </a>
              <a href="https://www.idfcfirstbank.com/credit-card" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#ED1C24' }}>
                {/* <img src="../../icon/idfc-logo.jpg" alt="IDFC First Bank" style={imgStyle} /> */}
                <p style={pStyle}>IDFC First Bank</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditCardApps;

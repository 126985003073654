import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../utils/api';
import '../../styles/registration.css';
import Header from '../common/Header'; // Adjusted import path
import Footer from '../common/Footer'; // Adjusted import path
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook


function EmployerRegistration() {
    const navigate = useNavigate(); // Initialize useNavigate

    const [formData, setFormData] = useState({
        company_name: '',
        email: '',
        location: '',
        country_code: '',
        phone_number: '',
        industry: '',
        year_of_establishment: '',
        address: '',
        gst_number: '',
        gst_documents: null,
    });

    const [errorMessages, setErrorMessages] = useState([]);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);

    useEffect(() => {
        document.title = "Promoterpal-Registration";
        const storedEmail = localStorage.getItem('userEmail');
        if (storedEmail) {
            setFormData(prevState => ({ ...prevState, email: storedEmail }));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, gst_documents: file });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                if (formData.hasOwnProperty(key)) {
                    if (key === 'gst_documents') {
                        formDataToSend.append(key, formData[key]);
                    } else {
                        formDataToSend.append(key, formData[key]);
                    }
                }
            }
            const response = await axios.post(`${BASE_URL}/api/registerEmployeer`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data && response.data.message) {
                localStorage.removeItem('userEmail');
                setRegistrationSuccess(true);
                navigate("/employer/registerSuccess");
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                const errors = Object.values(error.response.data.errors).flat();
                setErrorMessages(errors);
            } else {
                setErrorMessages(['Registration failed. Please try again.']);
            }
        }
    };

    return (
        <div>
           {/* <Header/> */}
            <h2 className='reg-form'>Company Registration</h2>
            {registrationSuccess && (
                <div id="success-message" style={{ color: 'green' }}>
                    Registration successful! Once admin approves the GST documents, you will be able to login. Click <a href="/employer/login">here</a> to login.
                </div>
            )}
            <div className="form-container-reg">
                <form className="registration-form" onSubmit={handleSubmit}>
                    <div className="form-field">
                        <input type="text" id="name" name="company_name" value={formData.company_name} onChange={handleChange} placeholder="Name" />
                    </div>
                    <div className="form-field required">
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email ID*" />
                    </div>

                    <div className="form-field location-input">
                        <input type="text" id="location" name="location" value={formData.location} onChange={handleChange} placeholder="Location" />
                    </div>

                    <div className="form-field countrycode-input">
                        <input type="text" id="country_code" name="country_code" value={formData.country_code} onChange={handleChange} placeholder="Country Code" />
                    </div>

                    <div className="form-field">
                        <input type="text" id="phone" name="phone_number" value={formData.phone_number} onChange={handleChange} placeholder="Phone Number" />
                    </div>

                    <div className="form-field">
                    <select
                        id="industry"
                        name="industry"
                        value={formData.industry}
                        onChange={(e) => setFormData({ ...formData, industry: e.target.value })}
                        required
                        >
                        <option value="">Select Industry</option>
                        <option value="consumer-durables">Consumer Durables</option>
                        <option value="telecom-mobile-phone">Telecom Mobile Phone</option>
                        <option value="large-retail">Large Retail</option>
                        <option value="automobiles">Automobiles</option>
                        <option value="shoes-leather">Shoes & Leather</option>
                        <option value="garments">Garments</option>
                        <option value="jewellery-pens-watches">Jewellery, Pens & Watches</option>
                        <option value="fmcg">FMCG</option>
                        <option value="medical">Medical</option>
                        <option value="hardware-sanitary">Hardware and Sanitary</option>
                        <option value="sports-health">Sports & Health</option>
                        <option value="books-education">Books & Education</option>
                        <option value="banking-insurance">Banking & Insurance</option>
                        <option value="real-estate">Real-estate</option>
                        <option value="furniture">Furniture</option>
                        <option value="others-specify">Others (Specify)</option>
                    </select>
                    </div>

                    <div className="form-field date-input">
                        <input type="text" id="year_of_establishment" name="year_of_establishment" value={formData.year_of_establishment} onChange={handleChange} placeholder="Year Of Establishment" />
                    </div>

                    <div className="form-field">
                        <textarea id="address" name="address" value={formData.address} onChange={handleChange} placeholder="Address"></textarea>
                    </div>

                    <div className="form-field">
                        <input type="text" id="gst_number" name="gst_number" value={formData.gst_number} onChange={handleChange} placeholder="GST Number" />
                    </div>
                    <div className="form-field">
                        <label htmlFor="gst_documents" className="file-label">
                            {formData.gst_documents ? formData.gst_documents.name : "Click Here To Upload GST Certificate"}
                            <input type="file" id="gst_documents" name="gst_documents" onChange={handleDocumentChange} style={{ display: 'none' }} />
                        </label>
                    </div>

                    <button type="submit" className="form-button">Register</button>
                </form>
            </div>
            {errorMessages.length > 0 && (
                <div style={{ color: 'red' }}>
                    <h3>Error:</h3>
                    <ul>
                        {errorMessages.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}

        <Footer />
        </div>

    );
}

export default EmployerRegistration;

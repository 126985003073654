import React from 'react';
import '../../../styles/store.css';
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea'; // Import ProfileArea component

function BookingAndFinance() {
  // const linkStyle = {
  //   textDecoration: 'none',
  //   color: 'inherit', // Inherit color from parent to ensure it matches with the <p> tag
  // };

  // const pStyle = {
  //   color: 'black',
  //   textDecoration: 'none',
  // };

  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
    width: '150px',
    height: '150px',
    borderRadius: '10px',
    color: 'white',
    fontSize: '16px',
    margin: '10px'
  };
  
  const pStyle = {
    margin: '10px 0 0 0',
    fontWeight: 'bold'
  };

  return (
    <div>
      <Header />
      <div className="container">
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea profileImage={profileImage} />
          <hr />
          <div className="payment-container">
            <a href="https://www.paisabazaar.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#0033cc' }}>
              {/* <img src="../../icon/paisa-bazaar-logo.png" alt="Paisa Bazaar" /> */}
              <p style={pStyle}>Paisa Bazaar</p>
            </a>
            <a href="https://www.paytm.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#00baf2' }}>
              {/* <img src="../../icon/paytm-logo.png" alt="Paytm" /> */}
              <p style={pStyle}>Paytm</p>
            </a>
            <a href="https://www.phonepe.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#5b259f' }}>
              {/* <img src="../../icon/phonepe-logo.png" alt="Phonepe" /> */}
              <p style={pStyle}>Phonepe</p>
            </a>
            <a href="https://pay.google.com" target="_blank" rel="noopener noreferrer" className="payment-button" style={{ ...linkStyle, backgroundColor: '#4285f4' }}>
              {/* <img src="../../icon/google-pay-logo.png" alt="Google Pay" /> */}
              <p style={pStyle}>Google Pay</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingAndFinance;

import React, { useState } from 'react';
import { BiFilter, BiSearch, BiX } from 'react-icons/bi';
import JobSeekerSidebar from '../../common/JobSeekerSidebar';
import profileImage from '../../../assets/images/profile.jpg';
import Header from '../../common/Header';
import ProfileArea from '../../common/ProfileArea';
import { BASE_URL } from '../../../utils/api';
import axios from 'axios';

function JobSearch({ job }) {
  const [showNotification, setShowNotification] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);

   const [appliedJobId, setAppliedJobId] = useState(null);
   const [isEmailSent, setIsEmailSent] = useState(false);
   const [isSending, setIsSending] = useState(false);

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const token = localStorage.getItem('token');

    // Filter out empty values from form data
    const filteredFormData = Object.fromEntries([...formData.entries()].filter(([key, value]) => value !== ''));

    // Convert filtered form data to URLSearchParams
    const queryParams = new URLSearchParams(filteredFormData).toString();

    try {
      const response = await fetch(`${BASE_URL}/api/job/search?${queryParams}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setSearchResults(data.data);
        setNoDataFound(false);
      } else {
        setSearchResults([]);
        setNoDataFound(true);
      }
    } catch (error) {
      console.error('Error searching jobs:', error);
    }
  };

  const formatText = (text) => {
    return text.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};


const handleApply = async (jobId) => {
  setIsSending(true);
  const token = localStorage.getItem('token');
  const jobSeekerId = localStorage.getItem('id');

  try {
    const response = await axios.post(
      `${BASE_URL}/api/apply/job`,
      {
        job_posting_id: jobId,
        job_seeker_id: jobSeekerId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data.message === 'Application sent successfully') {
      setAppliedJobId(jobId);
      setIsEmailSent(true);
    }
  } catch (error) {
    console.error('Error applying for job:', error);
  }finally {
    setIsSending(false);
 }
};

  return (
    <div>
      <Header />
      <div className="container" >
        <JobSeekerSidebar />
        <div className="main-content" id="main-content">
          <ProfileArea showNotification={showNotification} toggleNotification={toggleNotification} profileImage={profileImage} />
          <hr />

          {/* Search Bar */}
          <form id="filterForm" onSubmit={handleFilterSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid #ccc', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
            <label htmlFor="location" style={{ marginBottom: '8px' }}>Location:</label>
            <input type="text" id="location" name="location" style={{ marginBottom: '16px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', width: '100%', maxWidth: '300px' }} />

            <label htmlFor="industry" style={{ marginBottom: '8px' }}>Industry:</label>
            <select name="industry" style={{ marginBottom: '16px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', width: '100%', maxWidth: '300px' }}>
                <option value="">Select Industry</option>
                <option value="consumer-durables">Consumer Durables</option>
                <option value="telecom-mobile-phone">Telecom Mobile Phone</option>
                <option value="large-retail">Large Retail</option>
                <option value="automobiles">Automobiles</option>
                <option value="shoes-leather">Shoes & Leather</option>
                <option value="garments">Garments</option>
                <option value="jewellery-pens-watches">Jewellery, Pens & Watches</option>
                <option value="fmcg">FMCG</option>
                <option value="medical">Medical</option>
                <option value="hardware-sanitary">Hardware and Sanitary</option>
                <option value="sports-health">Sports & Health</option>
                <option value="books-education">Books & Education</option>
                <option value="banking-insurance">Banking & Insurance</option>
                <option value="real-estate">Real-estate</option>
                <option value="furniture">Furniture</option>
                <option value="others-specify">Others (Specify)</option>
              </select>

            <label htmlFor="salary" style={{ marginBottom: '8px' }}>Expected Salary Range:</label>
            <input type="text" id="salary" name="salary" style={{ marginBottom: '16px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', width: '100%', maxWidth: '300px' }} />

            <label htmlFor="company_name" style={{ marginBottom: '8px' }}>Company Name:</label>
            <input type="text" id="company_name" name="company_name" style={{ marginBottom: '16px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', width: '100%', maxWidth: '300px' }} />

            <label htmlFor="job_type" style={{ marginBottom: '8px' }}>Job Type:</label>
            <input type="text" id="job_type" name="job_type" style={{ marginBottom: '16px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', width: '100%', maxWidth: '300px' }} />

            <label htmlFor="experience_level" style={{ marginBottom: '8px' }}>Experience Level:</label>
            <input type="text" id="experience_level" name="experience_level" style={{ marginBottom: '16px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', width: '100%', maxWidth: '300px' }} />

            <button type="submit" style={{ marginBottom: '16px', padding: '8px 16px', borderRadius: '4px', background: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', width: '100%', maxWidth: '300px' }}>Apply Filters</button>
          </form>

          {/* Display search results or no data found message */}
          {noDataFound ? (
            <p>No data found</p>
          ) : (
            searchResults.map(job => (
              <div className="profile-card" key={job.id}>
                <div className="job-container">
                <img src="/icon/namsbel-logo.png" alt="Profile Photo" className="profile-photo" width="150" height="150" />
                  <div className="job-details">
                      <h3>{job.job_title}</h3>
                      <h4>{job.title}</h4>
                      <h4>{job.company_name}</h4>
                      <p>Industry: {formatText(job.job_industry)}</p>
                      <p>{job.state}</p>
                      <p>{job.city}</p>
                      <p>Posted {new Date(job.created_at).toLocaleDateString()}</p>
                  </div>
                  <div className="job-description-skills">
                      <h4>Job Description</h4>
                      <p>{job.job_description}</p>
                      <h4>Skills</h4>
                      <p>{job.job_skills}</p>
                      <h4>Positions</h4>
                      <p>{job.number_of_positions}</p>

                      {appliedJobId === job.id && (
                          <p style={{ color: 'green' }}>Application Sent Successfully</p>
                      )}
                      <button
                          className="btn-download"
                          onClick={() => handleApply(job.id)}
                          disabled={appliedJobId === job.id && isEmailSent}
                      >
                          {isSending ? 'Sending Application...' : isEmailSent ? 'Application Sent' : 'Apply now'}
                      </button>
                      {isSending && <div className="loader"></div>}
                  </div>
              </div>
              </div>
            ))
          )}

        </div>
      </div>
    </div>
  );
}

export default JobSearch;
